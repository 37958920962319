import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';

import './slider.scss';

const DEFAULT_SLIDER = (() => null);

class Slider extends React.Component {
	constructor(props) {
		super(props);
		const { direction } = props;
		this.state = {
			index: 0,
			direction: direction || 'left',
		};
	}

	componentDidMount() {
		const { rotate } = this.props;
		if (rotate) {
			this.timeout = window.setTimeout(this.rotate, rotate * 1000);
		}
	}

	/*componentWillReceiveProps(nextProps) {
		const { rotate } = this.props;
		this.updateState(nextProps);

		if (nextProps.rotate !== rotate) {
			window.clearTimeout(this.timeout);
			if (nextProps.rotate) {
				this.timeout = window.setTimeout(this.rotate, nextProps.rotate * 1000);
			}
		}
	}*/

	componentWillUnmount() {
		window.clearTimeout(this.timeout);
	}

	onClickNext = (e) => {
		e.stopPropagation();
		window.clearTimeout(this.timeout);
		this.next();
	}

	onClickPrevious = (e) => {
		e.stopPropagation();
		window.clearTimeout(this.timeout);
		this.previous();
	}

	rotate = () => {
		const { rotate } = this.props;
		this.next();
		this.timeout = window.setTimeout(this.rotate, rotate * 1000);
	}

	updateState = ({ index, direction }) => {
		const { children } = this.props;
		const update = {};
		if (Number.isInteger(index)) {
			const last = children.length - 1;
			update.index = (index > last || index < 0) ? 0 : index;
		}
		if (direction) {
			update.direction = direction;
		}
		this.setState(update);
	}


	next = () => {
		const { index } = this.state;
		const { children } = this.props;
		const last = children.length - 1;
		let nIndex = index + 1;
		const direction = 'left';
		nIndex = nIndex > last ? 0 : nIndex;
		this.updateState({ index: nIndex, direction });
	}

	previous = () => {
		const { index } = this.state;
		const { children } = this.props;
		const last = children.length - 1;
		let pIndex = index - 1;
		const direction = 'right';
		pIndex = pIndex < 0 ? last : pIndex;

		this.updateState({ index: pIndex, direction });
	}

	render() {
		const { children, className, controls } = this.props;
		const { direction, index } = this.state;
		const Slide = (children && children[index]) || DEFAULT_SLIDER;

		return (
			<section className={`slider ${className || ''}`}>
				<TransitionGroup
					className={`slider-transition-group slide-${direction}`}
				>
					<CSSTransition
						key={index}
						timeout={500}
						classNames="slide"
					>
						{Slide}
					</CSSTransition>
				</TransitionGroup>
				{
					children.length > 1 && controls !== false
					&& (
						<div className="arrow-container arrow-left" onClick={this.onClickPrevious}>
							<i className="fa fa-chevron-left arrow" />
						</div>
					)
				}
				{
					children.length > 1 && controls !== false
					&& (
						<div className="arrow-container arrow-right" onClick={this.onClickNext}>
							<i className="fa fa-chevron-right arrow" />
						</div>
					)
				}
			</section>
		);
	}
}

Slider.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	direction: PropTypes.string,
	controls: PropTypes.bool,
	rotate: PropTypes.number,
};

Slider.defaultProps = {
	children: null,
	className: '',
	controls: true,
	direction: null,
	rotate: 0,
};

export default Slider;
