import Helmet from 'react-helmet';
import React from 'react';

import LegalMentions from '../components/legal/mentions';
import Page from '../components/page/Page';

const {
	REACT_APP_EMAIL_PUBLIC,
	REACT_APP_WWW,
} = process.env;

export default () => (
	<Page className="PageLegal">
		<Helmet>
			<title>cherchebienneuf.fr – Mentions légales</title>
		</Helmet>
		<section className="page-center section last-section">
			<LegalMentions appWWW={REACT_APP_WWW} appEmailPublic={REACT_APP_EMAIL_PUBLIC} />
		</section>
	</Page>
);
