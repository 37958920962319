import PropTypes from 'prop-types';
import React from 'react';

import { getFileUrl } from '../../lib/file';

const SliderThumbnail = (props) => {
	const { file, onClick } = props;
	const { type } = file;
	const url = getFileUrl(file);

	if (type === 'video/mp4') {
		return (
			<video
				className="SliderThumbnail"
				src={url}
				onClick={() => { onClick(); }}
			/>
		);
	}

	return (
		<img
			alt="Miniature du slider de présentation du programme"
			className="SliderThumbnail"
			src={url}
			onClick={() => { onClick(); }}
		/>
	);
};

SliderThumbnail.propTypes = {
	file: PropTypes.shape({
		type: PropTypes.string,
	}).isRequired,
	onClick: PropTypes.func.isRequired,
};

export default SliderThumbnail;
