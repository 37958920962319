import React from 'react';
import PropTypes from 'prop-types';

import ProgrammeListItem from './programme-list-item';

import './programme-list.scss';

const ProgrammeList = (props) => {
	const { className, onClick, programmes } = props;

	return (
		<ul className={`ProgrammeList list-undecorated ${className}`}>
			{
				programmes.length
					? programmes.map(programme => (
						<li
							key={programme.id || programme.uuid}
							onClick={() => {
								if (onClick) {
									onClick(programme);
								}
							}}
						>
							<ProgrammeListItem programme={programme} />
						</li>
					))
					: <li className="no-result">Aucun résultat</li>
			}
		</ul>
	);
};

ProgrammeList.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	programmes: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		uuid: PropTypes.string,
	})),
};

ProgrammeList.defaultProps = {
	className: undefined,
	onClick: undefined,
	programmes: null,
};

export default ProgrammeList;
