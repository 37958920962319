import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Helmet from 'react-helmet';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';

// Lib
import { LOCATION_TYPE_BOUNDS } from '../../lib/location';
import * as SearchParams from '../../lib/search-params';

//Actions
import { searchProgramme } from '../../store/actions/programme';

//Components
import Page from '../../components/page/Page';
import SearchProgramme from '../../components/search-programme/search-programme';
import SearchResults from '../../components/search-results/search-results';

import './search.scss';

class PageProgrammeSearch extends PureComponent {
	constructor(props) {
		super(props);
		const { dispatchSearchProgramme } = props;
		this.memDispatchSearchProgramme = memoize(
			search => dispatchSearchProgramme(search),
		);
	}

	onClickProgrammeListItem = (programme) => {
		const { history } = this.props;
		history.push(`/programme/${programme.name}/p/${programme.uuid}`);
	}

	onMapBoundsChange= (bounds) => {
		const { search } = this.props;
		const data = Object.assign({}, search);
		data.location = {
			label: 'Position manuelle',
			value: '__MANUAL_POSITION__',
			type: LOCATION_TYPE_BOUNDS,
			bounds,
		};
		this.updateURL(data);
	}

	onSearchProgramme = (data) => {
		this.updateURL(data);
	}

	updateURL(search) {
		const { history } = this.props;
		const params = SearchParams.searchDataToParams(search);
		history.push(`/search#${params}`);
	}

	render() {
		const { programmes, search, searching } = this.props;
		this.memDispatchSearchProgramme(search);

		return (
			<Page className="PageProgrammeSearch">
				<Helmet>
					<title>cherchebienneuf.fr – Les offres de biens immobiliers neufs</title>
				</Helmet>
				<section className="PageProgrammeSearch-SearchProgramme-container">
					<SearchProgramme
						loading={searching}
						onSearch={this.onSearchProgramme}
						{...search}
						submitText="Filtrer"
						className="page-center"
					/>
				</section>

				<SearchResults
					mapBounds={search.location && search.location.bounds}
					onClickProgrammeListItem={this.onClickProgrammeListItem}
					onMapBoundsChange={this.onMapBoundsChange}
					search={search}
					programmes={programmes}
				/>
			</Page>
		);
	}
}

PageProgrammeSearch.propTypes = {
	dispatchSearchProgramme: PropTypes.func.isRequired,
	history: ReactRouterPropTypes.history.isRequired,
	programmes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	search: PropTypes.shape({
		location: PropTypes.shape({
			bounds: PropTypes.shape({}).isRequired,
		}).isRequired,
	}).isRequired,
	searching: PropTypes.bool,
};

PageProgrammeSearch.defaultProps = {
	searching: false,
};

const mapStateToProps = state => ({
	programmes: state.programme.programmesSearched,
	searching: state.programme.searching,
});

const mapDispatchToProps = dispatch => ({
	dispatchSearchProgramme: data => dispatch(searchProgramme(data)),
});

const PageProgrammeSearchContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PageProgrammeSearch);

export default (props) => {
	// eslint-disable-next-line react/prop-types
	const { location } = props;
	const searchData = SearchParams.paramsToSearchData(location);
	return <PageProgrammeSearchContainer {...props} search={searchData} />;
};
