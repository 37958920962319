import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Field from '../form/field/field';

import './checkboxField.scss';

class CheckboxField extends PureComponent {
	render() {
		const {
			checked,
			children,
			className,
			...props
		} = this.props;
		return (
			<label className="CheckboxField-label">
				<Field
					checked={checked}
					className={`CheckboxField ${className}`}
					type="checkbox"
					{...props}
				>
					<span>{children}</span>
				</Field>
			</label>
		);
	}
}

CheckboxField.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	checked: PropTypes.bool,
};

CheckboxField.defaultProps = {
	children: null,
	checked: null,
	className: '',
};

export default CheckboxField;
