const baseUrl = process.env.REACT_APP_API_URL;

const send = (url, data) => fetch(url, {
	headers: {
		'Content-Type': 'application/json',
	},
	method: 'POST',
	body: JSON.stringify(data),
})
	.then((resp) => {
		if (resp.status !== 200) {
			throw new Error(resp.statusText);
		}
		return resp.json();
	})
	.catch((err) => {
		throw new Error(err);
	});

const sendContactClient = (data) => {
	if (data.commercialAdvise && data.programmeUuid) {
		return send(`${baseUrl}/contact/commercial`, data);
	}
	return send(`${baseUrl}/contact/financial`, data);
};


export const sendProgrammeByEmail = (data) => send(`${baseUrl}/programme/share`, data);

export default sendContactClient;
