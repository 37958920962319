import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import React from 'react';

// Lib
import * as SearchParams from '../lib/search-params';

// Components
import SliderHome from '../components/slider-home/slider-home';
import SearchProgramme from '../components/search-programme/search-programme';
import Page from '../components/page/Page';
import ProgrammeList from '../components/programme/programme-list';

// Actions
import { getLastProgrammes } from '../store/actions/programme';

import imgConseils from '../images/conseils-pour-acheter.jpg';
import imgPret from '../images/pret-taux-zero.jpg';
import imgPinel from '../images/loi-pinel.jpg';
import luxuryProperties from '../images/luxury-properties.jpg';

import './home.scss';

class PageHome extends React.Component {
	constructor(props) {
		super(props);

		//Search programmes
		const { getLastProgrammes: actionGetLastProgrammes } = this.props;
		actionGetLastProgrammes();

		this.onSearchProgramme = this.onSearchProgramme.bind(this);
	}

	onSearchProgramme(search) {
		const { history } = this.props;
		const params = SearchParams.searchDataToParams(search);
		history.push(`/search#${params}`);
	}

	render() {
		const { lastProgrammes } = this.props;
		return (
			<Page className="PageHome">
				<Helmet>
					<title>cherchebienneuf.fr – Les offres de biens immobiliers neufs</title>
				</Helmet>
				<SliderHome />
				<section className="PageHome-search">
					<SearchProgramme onSearch={this.onSearchProgramme} className="page-center" />
				</section>

				<section className="PageHome-infos section">
					<h1 className="section-title">Nos conseils pour votre projet</h1>
					<div className="page-center">
						<article className="PageHome-infos-article">
							<img
								className="PageHome-infos-img"
								src={imgConseils}
								alt="Conseils pour acheter"
							/>
							<strong className="PageHome-infos-title">
								Simulez en ligne votre projet immobilier
							</strong>
							<p className="PageHome-infos-desc">
								Calculez votre capacité d’emprunt.
							</p>
							<p className="PageHome-infos-actions">
								<a
									className="btn btn-error"
									href="https://www.caisse-epargne.fr/cote-d-azur/particuliers/emprunter/simulation-credit-immobilier"
								>
									En savoir +
								</a>
							</p>
						</article>
						<article className="PageHome-infos-article">
							<img
								className="PageHome-infos-img"
								src={imgPret}
								alt="Prêt Taux Zéro"
							/>
							<strong className="PageHome-infos-title">
								Acheter son logement
							</strong>
							<p className="PageHome-infos-desc">
								La Caisse d&apos;Epargne vous accompagne dans la préparation et la
								mise en œuvre de votre projet immobilier, qu&apos;il s&apos;agisse de
								l&apos;acquisition de votre résidence principale ou secondaire.
							</p>
							<p className="PageHome-infos-actions">
								<a className="btn btn-error" href="https://www.caisse-epargne.fr/cote-d-azur/particuliers/emprunter/acheter-son-logement">
									En savoir +
								</a>
							</p>
						</article>
						<article className="PageHome-infos-article">
							<img
								className="PageHome-infos-img"
								src={imgPinel}
								alt="Loi Pinel"
							/>
							<strong className="PageHome-infos-title">
								Investir dans l&apos;immobilier
							</strong>
							<p className="PageHome-infos-desc">
								Assortis d&apos;avantages fiscaux, les placements immobiliers
								permettent de diversifier son patrimoine, de préparer sa
								retraite... Découvrez les solutions de financement de la
								Caisse d&apos;Epargne !
							</p>
							<p className="PageHome-infos-actions">
								<a className="btn btn-error" href="https://www.caisse-epargne.fr/cote-d-azur/particuliers/emprunter/investir-dans-l-immobilier">
									En savoir +
								</a>
							</p>
						</article>
					</div>
				</section>

				<section className="last-offers section">
					<h1 className="section-title">Nos dernières offres</h1>
					<ProgrammeList
						className="page-center"
						programmes={lastProgrammes}
					/>
				</section>

				<section className="PageHome-LP page-center last-section">
					<a href="https://luxuryproperties-caisse-epargne.fr/" title="Luxury Properties by Caisse d'Epargne">
						<img src={luxuryProperties} alt="Luxury Properties" />
					</a>
				</section>
			</Page>
		);
	}
}

PageHome.propTypes = {
	history: ReactRouterPropTypes.history.isRequired,
	lastProgrammes: PropTypes.arrayOf(PropTypes.shape({})),
	getLastProgrammes: PropTypes.func.isRequired,
};

PageHome.defaultProps = {
	lastProgrammes: [],
};

const mapStateToProps = (state) => ({
	lastProgrammes: state.programme.lastProgrammes,
});

const mapDispatchToProps = (dispatch) => ({
	getLastProgrammes: () => dispatch(getLastProgrammes()),
});

const PageHomeContainer = connect(mapStateToProps, mapDispatchToProps)(PageHome);

export default PageHomeContainer;
