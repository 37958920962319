import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';

import CopyButton from './copy-button';
import SocialsEmailShare from './email/share';
//import SocialsEmailButton from './email/button';

import './share.scss';


class SocialsShare extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMailShareOpen: false,
		};
		this.handleClickEmailButton = this.handleClickEmailButton.bind(this);
	}

	handleClickEmailButton() {
		const { isMailShareOpen } = this.state;
		this.setState({ isMailShareOpen: !isMailShareOpen });
	}

	render() {
		const { programmeUuid, url } = this.props;
		const { isMailShareOpen } = this.state;
		return (
			<div className="SocialsShare">
				<ul>
					<li>
						<FacebookShareButton url={url}>
							<FacebookIcon size={32} round />
						</FacebookShareButton>
					</li>
					<li>
						<LinkedinShareButton url={url}>
							<LinkedinIcon size={32} round />
						</LinkedinShareButton>
					</li>
					<li>
						<TwitterShareButton url={url}>
							<TwitterIcon size={32} round />
						</TwitterShareButton>
					</li>
					{/*<li>
						<SocialsEmailButton onClick={this.handleClickEmailButton} />
					</li>*/}
					<li>
						<CopyButton />
					</li>
				</ul>
				<SocialsEmailShare programmeUuid={programmeUuid} open={isMailShareOpen} />
			</div>
		);
	}
}

SocialsShare.propTypes = {
	programmeUuid: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
};

export default SocialsShare;
