import React from 'react';
import PropTypes from 'prop-types';

import LegalSection from './section';
import LegalArticle from './article';

const PolitiqueCookies = () => (
	<LegalSection>
		<h1 className="section-title">Politique de cookies</h1>
		<p>
			La Caisse d’Epargne Côte d’azur et ses partenaires utilisent des cookies et traceurs.
		</p>
		<LegalArticle>
			<h2>Qu&apos;est-ce qu&apos;un cookie ?</h2>
			<p>
				Un &quot;cookie&quot; est une suite d&apos;informations, généralement de petite
				taille et identifié par un nom, qui peut être transmis à votre
				navigateur par un site web sur lequel vous vous connectez. Votre navigateur web
				le conservera pendant une certaine durée, et le renverra au serveur web chaque
				fois que vous vous y re-connecterez.

			</p>
			<p>
				Les cookies peuvent avoir de nombreuses fonctions. La Caisse d’Epargne Côte d’azur
				les utilisent pour permettre le bon fonctionnement de notre site Internet,
				mesurer anonymement leur audience, personnaliser votre expérience pendant votre
				navigation, personnaliser l’assistance commerciale suite à votre navigation ou encore
				personnaliser les annonces publicitaires diffusées sur Internet en dehors
				de notre site Internet et de notre application mobile.
			</p>
			<p>
				Cette page détaille les finalités des traceurs déposés par La Caisse
				d’Epargne Côte d’azur et ses partenaires . En fonction de vos préférences renseignées
				sur le module « Vos paramètres de cookies », les traceurs exemptés de consentement ne sont
				pas déposés ou ne sont pas exploités
				(s&apos;il n&apos;est techniquement pas possible de conditionner leur dépôt).
			</p>
		</LegalArticle>
		<LegalArticle>
			<h2>Liste de cookies internes</h2>
			<ul>
				<li>
					<strong>Cookies techniques</strong><br />
					Il s&apos;agit des cookies nécessaires au fonctionnement de notre site et de notre
					application qui ne peuvent être désactivés. Sans ces cookies, vous ne pourriez
					pas utiliser ces applicatifs normalement.
					Plus spécifiquement, ces traceurs répondent aux finalités suivantes : permettre
					le bon fonctionnement du site et de l’application, faciliter l’utilisation
					en adaptant les fonctionnalités, assurer les services associés aux contrats souscrits.
					<br />
					<ul>
						<li>
							les traceurs conservant le choix exprimé
							par les utilisateurs sur le dépôt de traceurs.
						</li>
					</ul>
				</li>
			</ul>
		</LegalArticle>
	</LegalSection>
);

PolitiqueCookies.propTypes = {
	cookies: PropTypes.shape({
		get: PropTypes.func,
		set: PropTypes.func,
	}),
};

PolitiqueCookies.defaultProps = {
	cookies: {},
};

export default PolitiqueCookies;
