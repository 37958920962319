import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
import AnimateHeight from 'react-animate-height';

import Field from '@cecaz-immo/ui/dist/components/form/field/field';
import Form from '@cecaz-immo/ui/dist/components/form/form';

import './share.scss';

import { sendProgrammeByEmail } from '../../../lib/contact';
import shareByEmail from '../../../schemas/share-by-email';

const defaultMessage = `
Bonjour,

J’ai vu cette annonce immobilière sur cherchebienneuf, et je pense qu’elle pourrait t’intéresser.

À bientôt
`;

class SocialsEmailShare extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				name: 'François',
				email: 'f.michaudon@gmail.com',
				message: defaultMessage,
			},
			sendingError: false,
			isSent: false,
			isOpen: props.open,
			loading: false,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.onSubmitShare = this.onSubmitShare.bind(this);
		this.resetDateState = this.resetDateState.bind(this);
	}

	onSubmitShare(e) {
		e.preventDefault();
		const { programmeUuid } = this.props;
		const { data } = this.state;
		const dataToSend = Object.assign({}, data, { programmeUuid });
		this.setState({ loading: true });

		sendProgrammeByEmail(dataToSend)
			.then(() => {
				this.resetDateState();
				this.setState({
					isSent: true,
					loading: false,
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					sendingError: true,
					loading: false,
				});
			});
	}

	handleInputChange(event) {
		const { target } = event;
		const { name, value } = target;
		const { data } = this.state;

		const newData = Object.assign({}, data, { [name]: value });
		this.setState({ data: newData });
	}

	resetDateState() {
		//this.recaptchaRef.current.reset();
		this.setState({
			data: {
				name: '',
				email: '',
				message: '',
			},
		});
	}


	render() {
		const {
			data,
			isSent,
			isOpen,
			loading,
			sendingError,
		} = this.state;

		return (
			<AnimateHeight
				duration={400}
				height={isOpen ? 500 : 0}
			>
				<div className="SocialsEmailShare">
					<>
						<h3>Envoyer ce programme par email</h3>
						<Form
							className="SocialsEmailShare-form"
							data={data}
							schema={shareByEmail}
							onSubmit={this.onSubmitShare}
						>
							<label htmlFor="name">Votre nom</label>
							<Field
								component="input"
								type="text"
								id="name"
								name="name"
								placeholder="nom"
								value={data.name}
								className="inpt"
								onChange={this.handleInputChange}
							/>
							<label htmlFor="email">Destinataire</label>
							<Field
								component="input"
								type="text"
								id="email"
								name="email"
								placeholder="email"
								value={data.email}
								className="inpt"
								onChange={this.handleInputChange}
							/>
							<label htmlFor="message">Votre message</label>
							<Field
								component="textarea"
								rows={8}
								type="text"
								name="message"
								placeholder="message"
								value={data.message}
								className="inpt"
								onChange={this.handleInputChange}
								errorMessages={{
									maxLength: err => `Le contenu du message est trop
	long de ${err.params.limit - data.message.length} caractères.`,
								}}
							/>
							<button className="btn btn-red btn-submit" type="submit" disabled={loading}>
								{
									(loading && <i className="fa fa-spinner spinner" />)
										|| 'Envoyer'
								}
							</button>
							{
								isSent && <p className="success">Le programme a bien été partagé !</p>
							}
							{
								sendingError && (
									<p className="fail">Une erreur est survenue, merci de réssayer
										ultérieurement.
									</p>
								)
							}
						</Form>
					</>
				</div>
			</AnimateHeight>
		);
	}
}

SocialsEmailShare.propTypes = {
	open: PropTypes.bool.isRequired,
	programmeUuid: PropTypes.string.isRequired,
};

export default SocialsEmailShare;
