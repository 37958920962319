import PropTypes from 'prop-types';
import React from 'react';

import './table.scss';

const LegalTable = ({ children }) => (
	<table className="LegalTable table table-bordered">
		<tbody>
			{ children }
		</tbody>
	</table>
);

LegalTable.propTypes = {
	children: PropTypes.node,
};

LegalTable.defaultProps = {
	children: null,
};


export default LegalTable;
