import React from 'react';
import PropTypes from 'prop-types';

import { boundsEqual, areBoundsValid } from '../../lib/map-util';

import './map.scss';

export const MapContext = React.createContext({});

const DEFAULT_ZOOM = 12;
const DEFAULT_CAMERA_LOCATION = {
	lat: 43.7,
	lng: 6.637857700000001,
};

const { google } = global;

class CustomMap extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			map: undefined,
			// eslint-disable-next-line react/no-unused-state
			markerOpen: -1,
			// eslint-disable-next-line react/no-unused-state
			setMarkerOpen: this.setMarkerOpen,
		};
		this.map = React.createRef();
	}

	componentDidMount() {
		this.createMap();
	}

	componentDidUpdate() {
		this.updateBoundsOrLocation();
	}

	componentWillUnmount() {
		const { map } = this.state;
		if (map) {
			google.maps.event.clearInstanceListeners(this.map.current);
		}
	}

	get googleMap() {
		const { map } = this.state;
		return map;
	}

	setMarkerOpen = (id) => {
		this.setState({
			// eslint-disable-next-line react/no-unused-state
			markerOpen: id,
		});
	}

	createMap = () => {
		let {
			cameraLocation,
		} = this.props;

		const {
			bounds,
			onBoundsChange,
			zoom,
		} = this.props;

		cameraLocation = (cameraLocation
			&& cameraLocation.lat
			&& cameraLocation.lng) ? cameraLocation : DEFAULT_CAMERA_LOCATION;

		const mapOptions = {
			scrollwheel: false,
			zoom: zoom || DEFAULT_ZOOM,
			center: cameraLocation,
			minZoom: 8, // 11,
			maxZoom: 13, // 13,
		};

		// eslint-disable-next-line react/destructuring-assignment
		if (this.props.static) {
			Object.assign(mapOptions, {
				draggable: false,
				zoomControl: false,
				disableDoubleClickZoom: true,
				disableDefaultUI: true,
			});
		}

		const gMap = new google.maps.Map(this.map.current, mapOptions);

		google.maps.event.addListenerOnce(gMap, 'idle', () => {
			gMap.addListener('idle', () => {
				const currentBounds = gMap.getBounds().toJSON();

				if (!boundsEqual(bounds, currentBounds)
						&& !this.isFitBoundsCall
						&& onBoundsChange) {
					onBoundsChange(currentBounds);
				}

				this.isFitBoundsCall = false;
			});

			gMap.addListener('click', () => {
				if (global.clickOnOverlayView) {
					global.clickOnOverlayView = false;
					return;
				}
				this.setMarkerOpen(-1);
			});
		});
		this.setState({ map: gMap });
	}

	fitBounds = () => {
		const { bounds } = this.props;
		const { map } = this.state;

		if (areBoundsValid(bounds)) {
			const mapBounds = map.getBounds();
			const currentBounds = mapBounds && mapBounds.toJSON();

			if (!boundsEqual(bounds, currentBounds)) {
				this.isFitBoundsCall = true;
				map.fitBounds(bounds, 0);
			}
		}
	}

	updateCameraLocation = () => {
		const { cameraLocation } = this.props;
		const { map } = this.state;

		if (!cameraLocation
			|| !cameraLocation.lat
			|| !cameraLocation.lng) {
			return;
		}
		map.panTo(cameraLocation);
	}

	updateZoom = () => {
		const { map } = this.state;
		const { zoom } = this.props;

		if (!zoom) {
			return;
		}
		map.setZoom(zoom);
	}

	updateBoundsOrLocation = () => {
		const { bounds } = this.props;
		if (bounds) {
			this.fitBounds();
		} else {
			this.updateCameraLocation();
			this.updateZoom();
		}
	}

	render() {
		const {
			className,
			children,
			show,
		} = this.props;

		return (
			<MapContext.Provider value={this.state}>
				<div className={`Map ${className || ''} ${show}`}>
					<div className="Map-container" ref={this.map} />
					{ children || false }
				</div>
			</MapContext.Provider>
		);
	}
}

CustomMap.propTypes = {
	bounds: PropTypes.shape({}),
	cameraLocation: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	children: PropTypes.node,
	className: PropTypes.string,
	onBoundsChange: PropTypes.func,
	show: PropTypes.string,
	static: PropTypes.bool,
	zoom: PropTypes.number,
};

CustomMap.defaultProps = {
	bounds: undefined,
	cameraLocation: undefined,
	className: '',
	children: null,
	onBoundsChange: undefined,
	show: '',
	static: false,
	zoom: 12,
};

export default CustomMap;
