import { FieldError } from 'react-jsonschema-form-validation';
import Helmet from 'react-helmet';
import { useRouteMatch } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import React, { useEffect, useState } from 'react';

// components
import CheckboxField from '@cecaz-immo/ui/dist/components/checkbox/checkboxField';
import Field from '@cecaz-immo/ui/dist/components/form/field/field';
import Form from '@cecaz-immo/ui/dist/components/form/form';

import FormMentions from '../components/form/mentions/mentions';
import Page from '../components/page/Page';

// schemas
import sendContactClient from '../lib/contact';
import constactSchema from '../schemas/contact';

import './contact.scss';

const intialeState = {
	allowCommercialCecaz: false,
	allowCommercialPartner: false,
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	message: '',
	sendingError: false,
};

const PageContact = () => {
	const { trackEvent } = useMatomo();

	const match = useRouteMatch();
	const programmeUuid = match.params && match.params.uuid;
	const isContactFromProg = !!programmeUuid;
	const [loading, setLoading] = useState(false);
	const [isSent, setIsSent] = useState(false);
	const [sendingError, setSendingError] = useState(false);

	const [state, setState] = useState(intialeState);

	useEffect(() => {
		if (isContactFromProg) {
			setState((prevState) => ({
				...prevState,
				commercialAdvise: true,
				financialAdvise: false,
				programmeUuid,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				commercialAdvise: false,
				financialAdvise: true,
				programmeUuid,
			}));
		}
	}, [programmeUuid, isContactFromProg]);

	const resetState = () => {
		//this.recaptchaRef.current.reset();
		if (isContactFromProg) {
			setState({
				...intialeState,
				commercialAdvise: true,
				financialAdvise: false,
				programmeUuid,
			});
		} else {
			setState({
				...intialeState,
				commercialAdvise: false,
				financialAdvise: true,
				programmeUuid,
			});
		}
	};

	const handleChange = (data) => {
		setState(data);
	};

	const onSubmitContact = (e) => {
		e.preventDefault();
		const data = Object.assign(state);
		// const { programmeUuid } = state;
		delete data.defaultPhone;
		if (!data.programmeUuid) {
			delete data.programmeUuid;
		}
		delete data.isSent;
		delete data.sendingError;

		setLoading(true);
		sendContactClient(data)
			.then(() => {
				trackEvent({
					category: 'Contact',
					action: 'Send',
					value: programmeUuid
						? 'CONTACT_FORM_SEND_BY_PROGRAMME'
						: 'CONTACT_FORM_SEND_BY_HEADER',
				});
			})
			.then(() => {
				resetState();
				setIsSent(true);
				setLoading(false);
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log(err);
				setSendingError(true);
				setLoading(false);
			});
	};

	return (
		<Page className="PageContact">
			<Helmet>
				<title>cherchebienneuf.fr – Contact</title>
			</Helmet>
			<section className="PageContact-container page-center">
				<Form
					className="PageContact-form"
					data={state}
					schema={constactSchema}
					onSubmit={onSubmitContact}
					onChange={handleChange}
				>
					{
						state.commercialAdvise
							? (
								<div>
									<h1 className="PageContact-title">Vous souhaitez être contacté :</h1>
									<div className="row">
										<label htmlFor="commercial">
											Pour obtenir des informations sur le programme
											immobilier et sur son financement.
										</label>
									</div>
								</div>
							) : (
								<h1 className="PageContact-title ">
									Contacter un conseiller Caisse d’Epargne Côte d’Azur
								</h1>
							)
					}
					<Field
						component="input"
						type="text"
						name="lastName"
						placeholder="Nom"
						value={state.lastName}
						className="inpt"
					/>
					<Field
						component="input"
						type="text"
						name="firstName"
						placeholder="Prénom"
						value={state.firstName}
						className="inpt"
					/>
					<Field
						component="input"
						type="tel"
						name="phone"
						placeholder="Téléphone"
						value={state.phone}
						className="inpt"
						errorMessages={{
							minLength: () => `${state.phone} n'est un numéro valide`,
						}}
					/>
					<Field
						component="input"
						type="email"
						name="email"
						placeholder="Email"
						value={state.email}
						className="inpt"
						errorMessages={{
							format: () => `${state.email} n'est pas un email valide`,
						}}
					/>
					<Field
						component="textarea"
						placeholder="Votre message..."
						name="message"
						value={state.message}
					/>

					<div className="FormField">
						<CheckboxField name="allowCommercialCecaz" checked={state.allowCommercialCecaz}>
							Si vous souhaitez recevoir les offres commerciales de la Caisse
							d’Epargne par courrier électronique (mél, SMS, MMS), merci
							de cocher la case ci contre
						</CheckboxField>
					</div>

					<div className="FormField">
						<CheckboxField name="allowCommercialPartner" checked={state.allowCommercialPartner}>
							Si vous souhaitez recevoir des offres commerciales de nos
							partenaires par courrier électronique (mél, SMS, MMS),
							merci de cocher la case ci contre
						</CheckboxField>
					</div>

					{/*
					<ReCAPTCHA
						ref={this.recaptchaRef}
						onChange={this.onChangeReCAPTCHA}
						onExpired={this.onExpiredReCAPTCHA}
						sitekey="6LdPpXwUAAAAAF2tg-_Hg5q237qZRo9x3i56f_3V"
					/>
					*/}
					<FieldError name="recaptcha" />
					<button className="btn btn-red" type="submit" disabled={loading}>
						{
							(loading && <i className="fa fa-spinner spinner" />)
							|| 'Valider'
						}
					</button>
					{
						isSent && <p className="success">Votre message a bien été envoyé !</p>
					}
					{
						sendingError && (
							<p className="fail">Une erreur est survenue, merci de réssayer
								ultérieurement ou d’utiliser notre ligne direct.
							</p>
						)
					}
					<FormMentions />
				</Form>
				<aside className="PageContact-direct">
					<h1 className="PageContact-title">
						Vous souhaitez obtenir des informations sur le financement ?
					</h1>
					<p>
						Contactez directement un conseiller du lundi au vendredi de
						9h à 19h et le samedi de 9h à 17h
					</p>
					<div className="row">
						<label>Par téléphone</label>
						<a href="tel:0494098934">
							04.92.29.64.52 <br />
							<span className="info">appel non surtaxé</span>
						</a>
					</div>
					<div className="row">
						<label>Mon projet Immobilier</label>
						<a
							title="Simulez votre prêt immobilier"
							href="https://www.caisse-epargne.fr/cote-d-azur/particuliers/emprunter/simulation-credit-immobilier"
						>
							Simulez votre projet immobilier
						</a>
					</div>
				</aside>
			</section>
		</Page>
	);
};

export default PageContact;
