import React from 'react';
import PropTypes from 'prop-types';

import { geolocationAvailable, getCurrentPosition } from '../../lib/location';

import './my-location.scss';

class MyLocation extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		};

		this.findMyLocation = this.findMyLocation.bind(this);
	}

	findMyLocation(e) {
		e.preventDefault();
		const { onGeolocation } = this.props;
		this.setState({ loading: true });

		getCurrentPosition((position) => {
			this.setState({ loading: false });
			onGeolocation(position);
		}, (error) => {
			this.setState({ loading: false });
			console.warn(error);
		});
	}

	render() {
		const { loading } = this.state;
		return (
			<div className="MyLocation">
				{
					(loading)
						? <span><span className="fa fa-compass spinner" /> Calcul...</span>
						: (
							<button type="button" className="btn-location" onClick={this.findMyLocation} disabled={!geolocationAvailable}>
								<i className="ic ic-geoloc" />Autour de ma position
							</button>
						)
				}
			</div>
		);
	}
}

MyLocation.propTypes = {
	onGeolocation: PropTypes.func.isRequired,
};

export default MyLocation;
