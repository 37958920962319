import React from 'react';

//Components
import Slider from '../slider/slider';

import './slider-home.scss';

import program1Img from '../../images/programme-1.jpg';
import program2Img from '../../images/programme-2.jpg';
import program3Img from '../../images/programme-3.jpg';

const SliderHome = () => (
	<Slider className="big-slider" controls={false} rotate={4}>
		<div
			className="content"
			style={{
				background: `url(${program1Img})`,
				backgroundSize: 'cover',
				backgroundPosition: 'bottom center',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className="page-center">
				<p className="slider-slogan">
						Découvrez les offres de nos partenaires
				</p>
			</div>
		</div>
		<div
			className="content"
			style={{
				background: `url(${program2Img})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className="page-center">
				<p className="slider-slogan">
						Découvrez les offres de nos partenaires
				</p>
			</div>
		</div>
		<div
			className="content"
			style={{
				background: `url(${program3Img})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className="page-center">
				<p className="slider-slogan">
						Découvrez les offres de nos partenaires
				</p>
			</div>
		</div>
	</Slider>
);

export default SliderHome;
