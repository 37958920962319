const shareByEmail = {
	type: 'object',
	properties: {
		email: { type: 'string', format: 'email', maxLength: 255 },
		message: { type: 'string', maxLength: 1000 },
		name: { type: 'string', maxLength: 255 },
		recaptcha: { type: 'boolean', enum: [true] },
	},
	required: [
		'email',
		'message',
		'name',
		//'recaptcha',
	],
};


export default shareByEmail;
