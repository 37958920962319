const { REACT_APP_API_URL } = process.env;

export const LOCATION_TYPE_DEP = 'LOCATION_TYPE_DEP';
export const LOCATION_TYPE_CITY = 'LOCATION_TYPE_CITY';
export const LOCATION_TYPE_POSTAL_CODE = 'LOCATION_TYPE_POSTAL_CODE';
export const LOCATION_TYPE_POSITION = 'LOCATION_TYPE_POSITION';
export const LOCATION_TYPE_BOUNDS = 'LOCATION_TYPE_BOUNDS';

export function findLocations(input) {
	if (!input) {
		return Promise.reject();
	}

	const url = `${REACT_APP_API_URL}/locations/${input}`;

	return fetch(url)
		.then((response) => (response.json()))
		.then((result) => (result));
}

export function locationToGeometryBounds(location) {
	if (!location) {
		return Promise.reject();
	}

	const url = `${REACT_APP_API_URL}/boundsof/${location}`;

	return fetch(url)
		.then((response) => (response.json()))
		.then((result) => (result));
}

export const geolocationAvailable = !!navigator.geolocation;

export function getCurrentPosition(success, error) {
	if (!geolocationAvailable) {
		error(new Error('navigator.geolocation unavailable'));
		return;
	}

	success = success || (() => {});
	error = error || (() => {});

	navigator.geolocation.getCurrentPosition(success, error);
}
