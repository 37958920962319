const constactSchema = {
	type: 'object',
	properties: {
		allowCommercialCecaz: { type: 'boolean', enum: [true, false] },
		allowCommercialPartner: { type: 'boolean', enum: [true, false] },
		lastName: { type: 'string', maxLength: 255 },
		firstName: { type: 'string', maxLength: 255 },
		phone: { type: 'string', minLength: 10, maxLength: 20 },
		email: { type: 'string', format: 'email', maxLength: 255 },
		message: { type: 'string' },
		recaptcha: { type: 'boolean', enum: [true] },
	},
	required: [
		'lastName',
		'firstName',
		'phone',
		'email',
		'message',
	],
};


export default constactSchema;
