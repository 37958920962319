import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import LegalArticle from '@cecaz-immo/ui/dist/components/legal/article';
import LegalSection from '@cecaz-immo/ui/dist/components/legal/section';
import Page from '../components/page/Page';
import ProgrammeList from '../components/programme/programme-list';

// Actions
import { getLastProgrammes } from '../store/actions/programme';

import logoPtz from '../images/logo-ptz.png';

class PagePtz extends React.Component {
	constructor(props) {
		super(props);
		//Search programmes
		const { getLastProgrammes: actionGetLastProgrammes } = this.props;
		actionGetLastProgrammes();
	}

	render() {
		const { lastProgrammes } = this.props;
		return (
			<Page className="PagePtz">
				<Helmet>
					<title>cherchebienneuf.fr – Le prêt à taux zéro</title>
				</Helmet>
				<section className="page-center section">
					<LegalSection>
						<h1 className="section-title title-img">
							Le prêt à taux zéro
							<img src={logoPtz} alt="logo loi Pinel" width="410" />
						</h1>
						<p>L’État vous aide à acheter votre premier logement</p>
						<p>
							Le Prêt à Taux Zéro (PTZ) est un prêt immobilier réglementé
							attribué notamment sous conditions de ressources,
							créé pour favoriser l&apos;acquisition ou la construction de la première
							résidence principale
						</p>
						<ul>
							<li>Prêt sans intérêts</li>
							<li>Pas de frais de dossier</li>
							<li>Adaptation des remboursements aux capacités financières</li>
						</ul>
						<LegalArticle>
							<h2>Le PTZ : une solution de financement idéale pour
							la première acquisition d&apos;une résidence principale
							</h2>
							<p>
							Vous projetez d&apos;acquérir ou de faire construire votre résidence principale ?
							Le PTZ(1), dont les intérêts sont pris en charge par l&apos;Etat, permet de
							financer à coût nul, hors frais d&apos;assurance et de garantie, une partie de
							votre projet immobilier.
							Accordé notamment sous conditions de ressources,
							il vient en complément d&apos;autres prêts.
							Les conditions de remboursement sont fixées en fonction de vos ressources.
							</p>
						</LegalArticle>
						<LegalArticle>
							<h2>Le PTZ, pour qui ?</h2>
							<p>
							Pour y accéder, l&apos;emprunteur doit remplir trois conditions :
							</p>
							<ul>
								<li>il ne doit pas avoir été propriétaire de sa résidence principale au
								cours des deux années qui précèdent l’octroi de ce prêt à 0%(1)
								</li>
								<li>il doit disposer de ressources inférieures à un plafond réglementaire</li>
								<li>il doit occuper le logement financé en tant que résidence principale,
								de plus il doit s’agir d’un logement neuf,
								ou d’un logement ancien sous conditions de travaux(2) ou bien
								encore d’un logement issu du parc HLM et satisfaisant certains critères
								</li>
							</ul>
							<p>Le montant du PTZ(1) est défini en fonction du coût total de l’opération,
							de la composition du ménage, de la zone géographique et des caractéristiques
							du logement.
							</p>
						</LegalArticle>
						<LegalArticle>
							<h2>Toutes les informations utiles pour le PTZ</h2>
							<p>Eligibilité : particuliers primo-accédants (sauf cas particuliers ),
							sous conditions de ressources, qui n&apos;ont pas été propriétaires de
							leur résidence principale dans les deux ans précédant l&apos;offre de prêt.
							Le projet financé doit respecter la réglementation en vigueur et doit servir
							à la résidence principale. Un seul PTZ(1) peut être octroyé par ménage
							et par opération immobilière.<br />
							Durée : de 12 à 25 ans<br />
							Modalités de remboursement fixées en fonction des ressources du ménage.<br />
							</p>
							<p>
								Pour bénéficier de plus d&apos;informations,
								<a
									title="informations suppémentaires"
									href="http://www.lesclesdelabanque.com/Web/Cdb/Particuliers/Content.nsf/DocumentsByIDWeb/6WGJ2Y"
								> cliquez ici.
								</a>
							</p>
							<p>
								Pour consulter le site officiel de la Convention AERAS,
								<a
									title="Convention AERAS"
									href="http://www.aeras-infos.fr/cms/sites/aeras/accueil.html"
								> cliquez ici.
								</a>
							</p>
						</LegalArticle>
						<p className="LegalSection-notes">
						(1) Sous réserve du respect des dispositions réglementaires du PTZ
						et d’acceptation de votre dossier par la Caisse d’Epargne locale,
						prêteur. Le PTZ est un prêt en faveur des primo-accédants sans intérêt
						accessible sous conditions de ressources qui permet de financer une partie
						de leur résidence principale dans le neuf ou dans l&apos;ancien, sous conditions.
						Les intérêts sont pris en charge par l’État. L’emprunteur dispose d’un délai
						de réflexion de dix jours avant d’accepter l’offre de crédit. La vente est
						subordonnée à l’obtention du prêt. Si celui-ci n’est pas obtenu, le vendeur
						doit rembourser les sommes versées.
							<br />
						(2) Sous conditions de travaux telles qu’énoncées par les articles L 31-10-2
						et R 31-10-2 du Code de la construction et de l’habitation.
						</p>
					</LegalSection>
				</section>
				<section className="last-offers section last-section">
					<h1 className="section-title">Nos dernières offres</h1>
					<ProgrammeList
						className="page-center"
						programmes={lastProgrammes}
					/>
				</section>
			</Page>
		);
	}
}

PagePtz.propTypes = {
	getLastProgrammes: PropTypes.func.isRequired,
	lastProgrammes: PropTypes.arrayOf(PropTypes.shape({})),
};

PagePtz.defaultProps = {
	lastProgrammes: [],
};

const mapStateToProps = state => ({
	lastProgrammes: state.programme.lastProgrammes,
});

const mapDispatchToProps = dispatch => ({
	getLastProgrammes: () => dispatch(getLastProgrammes()),
});

const PagePtzContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PagePtz);

export default PagePtzContainer;
