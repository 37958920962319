import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { InfoBox } from 'google-maps-infobox';

import { MapContext } from './map';
import { MarkerContext } from './marker';

import './info-window.scss';

const { google } = global;

class InfoWindow extends React.Component {
	componentWillMount() {
		const { map } = this.props;
		const div = document.createElement('div');

		this.domNode = div;

		this.infowindow = new InfoBox({
			content: undefined,
			map,
			disableAutoPan: false,
			maxWidth: 280,
			pixelOffset: new google.maps.Size(-160, 0),
			zIndex: null,
			boxStyle: {
				background: 'transparent',
				padding: '0',
			},
			alignBottom: true,
			closeBoxMargin: '0px 0px 0px 0px',
			closeBoxURL: '',
			infoBoxClearance: new google.maps.Size(1, 1),
			isHidden: false,
			pane: 'floatPane',
			enableEventPropagation: true,
		});

		this.infowindow.setContent(div);
		this.infowindow.close();

		google.maps.event.addDomListener(div, 'click', () => {
			global.clickOnOverlayView = true;
		});

		google.maps.event.addListener(this.infowindow, 'closeclick', () => {
			const { context } = this;
			context.closeInfoWindow();
		});
	}

	componentWillReceiveProps(nextProps) {
		const {
			map,
			markerId,
			markerOpen,
		} = this.props;
		if (nextProps.map !== map) {
			this.infowindow.setMap(nextProps.map);
		}
		if (nextProps.markerOpen !== markerOpen
			|| nextProps.markerId !== markerId) {
			if (nextProps.markerOpen === markerId) {
				this.infowindow.open(nextProps.map, nextProps.marker);
			} else {
				this.infowindow.close();
			}
		}
	}

	componentWillUnmount() {
		if (this.infowindow) {
			this.infowindow.setMap(null);
		}
	}

	render() {
		const { children } = this.props;
		return ReactDOM.createPortal(
			<div
				className="InfoWindow cols-2"
				onClick={(e) => {
				// The following is totally useless, the event
				// will still propagate to map :/
				// This is why global.clickOnOverlayView has been invented in 2017 AD
				// https://github.com/tomchentw/react-google-maps/issues/84
					e.stopPropagation();
				}}
			>
				<div className="InfoWindow-container">
					{ children }
				</div>
				<span className="InfoWindow-arrow" />
			</div>,
			this.domNode,
		);
	}
}

InfoWindow.propTypes = {
	children: PropTypes.node,
	map: PropTypes.shape({}),
	marker: PropTypes.shape({}),
};

InfoWindow.defaultProps = {
	children: null,
	map: {},
	marker: {},
	isInfoWindowOpen: false,
};

export default props => (
	<MapContext.Consumer>
		{ ({ map, markerOpen, setMarkerOpen }) => (
			<MarkerContext.Consumer>
				{ ({ closeInfoWindow, marker, markerId }) => (
					<InfoWindow
						closeInfoWindow={closeInfoWindow}
						map={map}
						marker={marker}
						markerId={markerId}
						markerOpen={markerOpen}
						setMarkerOpen={setMarkerOpen}
						{...props}
					/>
				)}
			</MarkerContext.Consumer>
		)}
	</MapContext.Consumer>
);
