import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import memoize from 'memoize-one';

import Slider from '../slider/slider';
import { RangePriceDisplay, RangeRoomsDisplay } from '../range/range-display';
import { calculatePricesAndRoomsProgramme } from '../../store/actions/programme';
import { getFileUrl } from '../../lib/file';

import './programme-list-item.scss';

function getQuarterString(number, small) {
	let dimin;

	switch (number) {
	case 1:
		dimin = 'er';
		break;
	case 2:
		dimin = 'nd';
		break;
	default:
		dimin = small ? 'e' : 'ème';
	}

	const str = small ? 'T' : 'trimestre';

	return `${number}${dimin} ${str}`;
}

class ProgrammeListItem extends React.Component {
	constructor(props) {
		super(props);
		this.memoPriceRoomsProgramme = memoize(calculatePricesAndRoomsProgramme);
	}

	render() {
		const {
			Actions,
			className,
			editionDetail,
			Overlay,
			programme,
		} = this.props;
		this.memoPriceRoomsProgramme(programme);

		return (
			<article className={`ProgrammeListItem ${className || ''}`}>
				{editionDetail
					&& (
						<div className="info">
							<p className="ProgrammeListItem-field hidden-cols-2">
								<span className="ProgrammeListItem-field-label">Date de création</span>
								<em className="ProgrammeListItem-field-value">{programme.createAt}</em>
							</p>
							<p className="ProgrammeListItem-field hidden-cols-2">
								<span className="ProgrammeListItem-field-label">Date de la dernière modification</span>
								<em className="ProgrammeListItem-field-value">{programme.updateAt}</em>
							</p>
							)
							{programme.publishAt
								&& (
									<p className="ProgrammeListItem-field hidden-cols-2">
										<span className="ProgrammeListItem-field-label">Date de publication</span>
										<em className="ProgrammeListItem-field-value">{programme.publishAt}</em>
									</p>
								)
							}
						</div>
					)
				}

				<Slider className="ProgrammeListItem-img">
					{
						programme.files && programme.files.map((file, i) => (
							<div className="slider-centered-img" key={file.name}>
								<img src={file.preview || getFileUrl(file)} alt={`${programme.name} slider ${i + 1}`} />
							</div>
						))
					}
				</Slider>

				<div className="ProgrammeListItem-properties">
					<p className="ProgrammeListItem-field">
						<span className="ProgrammeListItem-field-label hidden-cols-2">Programme</span>
						<em className="ProgrammeListItem-field-value ProgrammeListItem-name">{programme.name}</em>
					</p>

					<p className="ProgrammeListItem-field">
						<span className="ProgrammeListItem-field-label hidden-cols-2">Localisation</span>
						<em className="ProgrammeListItem-field-value ProgrammeListItem-address">{programme.district}</em>
					</p>

					<p className="ProgrammeListItem-field">
						<span className="ProgrammeListItem-field-label hidden-cols-2">Prix</span>
						<em className="ProgrammeListItem-field-value">
							<RangePriceDisplay min={programme.minPrice} max={programme.maxPrice} />
						</em>
					</p>

					<p className="ProgrammeListItem-field">
						<span className="ProgrammeListItem-field-label hidden-cols-2">Pièces</span>
						<em className="ProgrammeListItem-field-value">
							<RangeRoomsDisplay min={programme.minRooms} max={programme.maxRooms} />
						</em>
					</p>

					<p className="ProgrammeListItem-field hidden-cols-2">
						<span className="ProgrammeListItem-field-label">Livraison</span>
						<em className="ProgrammeListItem-field-value">
							{getQuarterString(programme.quarterDelivery)} {programme.yearDelivery}
						</em>
					</p>
				</div>

				<div className="ProgrammeListItem-bottom-right-corner">
					{/*<em className="visible-cols-2 delivery">
					{getQuarterString(programme.quarterDelivery, true)} {programme.yearDelivery}
				</em>
				*/}
					<Link
						to={`/programme/${programme.name}/p/${programme.uuid}`}
						className={`btn btn-red hidden-cols-2 ${Actions ? 'btn-icon' : ''}`}
					>
						{!Actions && <span>Voir le programme</span>}<i className="ic ic-voir" />
					</Link>

					{Actions}

				</div>
				{Overlay}
			</article>
		);
	}
}

ProgrammeListItem.propTypes = {
	Actions: PropTypes.node,
	className: PropTypes.string,
	editionDetail: PropTypes.bool,
	Overlay: PropTypes.node,
	programme: PropTypes.shape({
		description: PropTypes.string,
		district: PropTypes.string,
		files: PropTypes.array,
		name: PropTypes.string,
		quarterDelivery: PropTypes.number,
		minPrice: PropTypes.number,
		maxPrice: PropTypes.number,
		minRooms: PropTypes.number,
		maxRooms: PropTypes.number,
		uuid: PropTypes.string,
		yearDelivery: PropTypes.string,
		createAt: PropTypes.string,
		updateAt: PropTypes.string,
		publishAt: PropTypes.string,
	}).isRequired,
};

ProgrammeListItem.defaultProps = {
	Actions: null,
	className: '',
	editionDetail: false,
	Overlay: null,
};

export default ProgrammeListItem;
