import PropTypes from 'prop-types';
import React from 'react';

import './section.scss';

const LegalSection = ({ children }) => (
	<section className="LegalSection">
		{ children }
	</section>
);

LegalSection.propTypes = {
	children: PropTypes.node,
};

LegalSection.defaultProps = {
	children: null,
};


export default LegalSection;
