import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import LegalArticle from '@cecaz-immo/ui/dist/components/legal/article';
import LegalSection from '@cecaz-immo/ui/dist/components/legal/section';

const LegalCGU = ({
	appLegalEmail,
	appWWW,
}) => (
	<section className="page-center section last-section">
		<LegalSection>
			<span className="text-center">{appWWW}</span>
			<h1 className="section-title">Conditions générales d’utilisation</h1>
			<LegalArticle>
				<h2>Préambule</h2>
				<p>
					Les présentes «conditions générales d’utilisation » ont pour objet l’encadrement
					juridique des modalités de mise à disposition des services du site
					www.cherchebienneuf.fr et leur utilisation par « l’Utilisateur ».
					Les conditions générales d’utilisation doivent être lues et acceptées par
					tout Utilisateur souhaitant accéder au site. Elles constituent le contrat
					entre le site et l’Utilisateur. L’accès au site par l’Utilisateur signifie
					son acceptation des présentes conditions générales d’utilisation.
					Ces Conditions Générales peuvent être modifiées à tout moment par
					la Caisse d’Epargne Côte d’Azur
					et entrent en vigueur à compter de leur mise en ligne sur le Site.
				</p>
				<p>Les Conditions Générales applicables sont celles en vigueur au moment
				de l’accès au site www.cherchebienneuf.fr.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 1. Définition des termes</h2>
				<ul>
					<li>
						<strong>Conseiller Caisse d’Epargne Côte d’Azur :</strong> désigne le conseiller
						de la Caisse d’Epargne Côte d’Azur contacté par les Utilisateurs par rapport à
						un bien immobilier du Promoteur figurant sur son Espace.
					</li>
					<li>
						<strong>Conseiller Promoteur :</strong> désigne le conseiller du Promoteur
						contacté par les Utilisateurs par rapport à un bien immobilier du Promoteur figurant
						sur son Espace.
					</li>
					<li>
						<strong>Cookies :</strong> désigne toute information déposée sur le disque dur
						d’un Utilisateur par le serveur du Site qu’il visite.
					</li>
					<li>
						<strong>Donnée personnelle :</strong> désigne toute «toute information se rapportant
						à une personne physique identifiée ou identifiable
						(ci-après dénommée «personne concernée»); est réputée être une «personne physique
						identifiable» une personne physique qui peut être identifiée,
						directement ou indirectement,
						notamment par référence à un identifiant, tel qu’un nom, un numéro d’identification,
						des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments
						spécifiques propres à son ide ntité physique, physiologique, génétique, psychique,
						économique, culturelle ou sociale».
					</li>
					<li>
						<strong>Programme immobilier (ou Programme) :</strong> désigne tout programme
						immobilier du Promoteur, et dont un descriptif figure sur le Site.
					</li>
					<li>
						<strong>Promoteur :</strong> désigne tout professionnel dont la mission consiste
						à vendre des espaces construits ou prêts à construire financés par la Caisse
						d’Epargne Côte d’Azur qui utilise www.cherchebienneuf.fr ou l’un des services
						proposés par www.cherchebienneuf.fr.
					</li>
					<li>
						<strong>Site :</strong> désigne le site internet de la Caisse d’Epargne
						Côte d’Azur www.cherchebienneuf.fr
					</li>
					<li>
						<strong>Utilisateur :</strong> désigne toute personne qui utilise
						www.cherchebienneuf.fr ou l’un des services proposés par www.cherchebienneuf.fr
					</li>
				</ul>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 2. Informations générales</h2>
				<p>
					Les Conditions Générales s’appliquent à tout accès et toute consultation du
					Site par un Utilisateur ainsi qu’à toute utilisation du Site par un Utilisateur.
					L’acceptation des Conditions Générales par tout Utilisateur est un préalable
					indispensable et obligatoire. Cette acceptation résulte pour l’Utilisateur,
					de l’accès au Site et de son clic d’acceptation sur la case figurant en regard
					de la mention « J’accepte les conditions générales d’utilisation ».
				</p>
				<p>
					L’Utilisateur reconnaît expressément qu’il a pris connaissance et accepté
					les présentes Conditions générales et informations légales et s’engage à
					les respecter.
				</p>
				<p>
					Pour des raisons de maintenance mais également en cas de force majeure,
					difficultés informatiques, difficultés liées aux réseaux de télécommunications
					ou autres difficultés techniques, l’Editeur pourra interrompre l’accès au site et
					ne saurait être tenu responsable de l’impossibilité d’accéder au présent site.
					L’accès à tout ou partie des produits et services décrits sur ce site peut faire
					l’objet de restrictions à l’égard de certaines personnes ou de certains pays.
					Aucun des produits ou services présentés ici ne sera fourni à une personne
					si la loi de son pays d’origine, ou de tout autre pays qui la concernerait,
					l’interdit.
				</p>
				<p>
					La Caisse d’Epargne Côte d’Azur se réserve le droit d’apporter des modifications
					aux Conditions Générales. Toute modification entre en vigueur à compter
					de sa date de mise en ligne, ou le cas échéant à toute autre date qui serait
					indiquée dans toute condition particulière applicable au service.
					Tout Utilisateur est réputé avoir accepté la nouvelle version des Conditions Générales,
					du Site et/ou des services du simple fait de l’accès au Site
					(pour tout Utilisateur et/ou de la poursuite de l’utilisation des services
					(pour tout Utilisateur).
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 3. Objet du Site</h2>
				<p>
					La Caisse d’Epargne Côte d’Azur met à disposition de ses
					Utilisateurs un Site permettant d’accéder aux offres relatives
					aux Programmes immobiliers ainsi que de réaliser les fonctionnalités
					figurant à l’article 4 des présentes.
				</p>
			</LegalArticle>

			<LegalArticle>
				<h2>Article 4. Descriptif du Site</h2>
				<p>
					L’accès à ce Site est gratuit. Les frais d’accès et d’utilisation du réseau
					de communication électronique sont à la charge de l’Utilisateur,
					selon les modalités fixées par ses fournisseurs d’accès et opérateurs de
					communication électronique. L’Utilisateur est entièrement responsable de la
					fourniture et de l’utilisation des équipements nécessaires pour la connexion
					au Site.
				</p>
				<p>
					L’Utilisateur reconnaît de disposer de la compétence et des
					moyens nécessaires pour accéder et utiliser ce Site.
				</p>
				<p>
					Il permet aux Utilisateurs d’accéder à toutes les offres de Programmes immobiliers.
				</p>
				<p>
					L’Utilisateur peut contacter un Conseiller de la Caisse d’Epargne Côte
					d’Azur pour des questions ou demandes concernant le financement
					d’un Programme immobilier.
					L’Utilisateur peut contacter un Conseiller Promoteur pour des questions
					ou demandes concernant un Programme Immobilier.<br />
					Pour accéder à ce formulaire :
				</p>
				<ul>
					<li>L’utilisateur clique sur l’onglet contact</li>
					<li>Il doit renseigner les informations suivantes :</li>
					<ul>
						<li>Nom</li>
						<li>Prénom</li>
						<li>Adresse mail</li>
						<li>Téléphone</li>
						<li>Description de la demande (facultatif)</li>
					</ul>
				</ul>
				<h3> Informations mises à disposition sur le site ou sur les sites tiers référencés</h3>
				<p>
					L’Editeur s’efforce d’assurer l’exactitude et la mise à jour
					des informations à caractère général, notamment financières,
					diffusées sur ce site, dont elle se réserve le droit de modifier et corriger
					le contenu à tout moment et sans préavis. En conséquence, l’Editeur ne garantit
					en aucune façon la complétude, la précision, l’exactitude, l’exhaustivité ou
					l’adéquation, des informations mises à disposition sur ce site, y compris
					l’ensemble des liens hypertextes ou toute autre liaison informatique utilisée,
					directement ou indirectement, à partir de ce site.
				</p>
				<p>
					Les aspects financiers et performances des produits indiquées sur
					ce site ne sont que le reflet d’une situation constatée à un instant
					donné et ne sauraient, en aucun cas, préjuger de l’évolution des taux et des cours
					des produits concernés. A cet égard, l’Editeur décline toute responsabilité dans
					l’utilisation qui pourrait être faite de ces informations et des conséquences qui
					pourraient en découler, notamment au niveau des décisions qui pourraient être prises
					ou des actions qui pourraient être entreprises à partir desdite
					informations. Vous demeurez
					seul et unique responsable de l’usage des informations et des résultats obtenus
					à partir de ces informations.
				</p>
				<p>
					L’Editeur attire tout particulièrement votre attention sur le fait que l’utilisation
					et l’interprétation des informations relatives aux produits financiers peut parfois
					nécessiter des connaissances spécifiques et approfondies. Il appartient à toute personne
					intéressée de vérifier les informations mises à disposition et d’en faire un
					usage approprié.
				</p>
				<p>L’Editeur décline en conséquence toute responsabilité :</p>
				<ul>
					<li>
						en cas d’imprécision, inexactitude, erreur ou omission
						portant sur des informations disponibles sur le site ou sur les
						sites de tiers référencés à partir des liens hypertextes ;
					</li>
					<li>
						d’une absence de disponibilité des informations ;
					</li>
					<li>
						pour tous dommages, directs et/ou indirects, quelles qu’en soient
						les causes, origines,
						nature ou conséquences, provoqués à raison de l’accès de quiconque au
						site ou de l’impossibilité
						d’y accéder ;
					</li>
					<li>
						de l’utilisation du site et/ou du crédit accordé à une quelconque
						information provenant
						directement ou indirectement du site ;
					</li>
					<li>
						de décisions prises sur la base d’une information contenue sur le site ou sur
						le site de tiers référencés à partir des liens hypertextes, et de l’utilisation qui
						pourrait en être faite par des tiers.
					</li>
				</ul>
			</LegalArticle>

			<LegalArticle>
				<h2>Article 5. Obligations de l’Utilisateur</h2>
				<p>
					L’Utilisateur s’engage à ne pas reproduire, diffuser ou faire diffuser par tout moyen
					direct ou indirect le contenu total ou partiel des Programmes immobiliers figurant
					sur le Site, les offres ayant été négociées spécifiquement dans le cadre de la relation
					que les Promoteurs entretiennent avec la Caisse d’Epargne Côte d’Azur.
				</p>
				<p>
					Il est strictement interdit de mettre en place un lien hypertexte en
					direction du site sans l’autorisation écrite et préalable de l’Editeur.
					Toute demande tendant à cette fin doit être
					{appLegalEmail}
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 6. Propriété Intellectuelle</h2>
				<p>
				La Caisse d’Epargne Côte d’Azur exploite le Service.<br />
				Le site est la propriété de la Caisse d’Epargne Côte d’Azur, à l’exception des marques,
				logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs.<br />
				Toute représentation ou reproduction en partie ou en totalité est interdite sans
				l’accord exprès de la Caisse d’Epargne Côte d’Azur et constituerait
				une contrefaçon sanctionnée
				par les articles L. 335-2 et suivants du Code de la Propriété
				Intellectuelle susceptible d’engager
				la responsabilité civile et pénale du Contrefacteur.
				</p>
			</LegalArticle>

			<LegalArticle>
				<h2>Article 7. Sous-Traitance</h2>
				<p>
					La Caisse d’Epargne Côte d’Azur pourra sous-traiter tout ou partie de se
					obligations à des tiers, à condition cependant que la Caisse d’Epargne
					Côte d’Azur reste garante de la bonne exécution des présentes.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 8. Cookies</h2>
				<p>
					L’Utilisateur est informé que ce site a recours à des témoins
					de connexion (usuellement dénommé « cookie ») qui peuvent s’installer
					automatiquement et être conservés temporairement en mémoire ou sur son
					disque dur. Les cookies utilisés permettent notamment de faciliter
					la navigation sur le Site. Un cookie est un mini-fichier contenant
					quelques lignes de caractères alphanumériques placé sur votre disque
					dur par le serveur du site ou de l’application mobile que vous visitez,
					ou par un serveur tiers (par exemple un service de web analytique),
					cet élément peut servir à enregistrer des informations relatives à
					la navigation de l’Utilisateur et à rationaliser les procédures
					d’enregistrement ou de mesure d’audience
				</p>
				<p>
						Il contient plusieurs données : le nom du serveur qui l’a déposé
						; un identifiant sous forme de numéro unique, éventuellement une date d’expiration.
				</p>
				<p>
					Ainsi, lorsque vous visitez le site pour chercher, lire ou télécharger
					de l’information, certains renseignements vous concernant peuvent
					être recueillis, tels le nom du domaine et de l’ordinateur hôte à
					partir duquel vous naviguez sur internet, l’adresse du protocole internet
					(IP) de l’ordinateur utilisé, la date et l’heure de la navigation et les
					URL à partir desquelles vous êtes passé. Ces renseignements sont utilisés
					pour analyser et mesurer la fréquentation du site et pour aider à la
					rendre plus utile. Ils sont détruits après un certain temps.
				</p>
				<p>
					L’Utilisateur qui le désire peut refuser les cookies en modifiant
					les paramètres de son navigateur.
				</p>
				<p>
					Les cookies mis en œuvre ne permettent pas un traçage de l’internaute.
				</p>
				<p>
					Cette manipulation entraînera cependant la suppression de tous les
					cookies utilisés par le navigateur, y compris ceux employés par d’autres
					sites internet, ce qui peut conduire à la perte de certaines informations ou réglages.
				</p>
				<p>
					La suppression des cookies peut être de nature à altérer, voire à
					rendre impossible la navigation sur le Site. Pour plus d’informations,
					notamment concernant la suppression et la gestion des cookies,
					l’Utilisateur est invité à consulter la page internet suivante :
					<Link className="link" to="/politique-cookies">Politique des Cookies</Link>
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 9. Protection des données à caractère personnel</h2>
				<p>
					Les données à caractère personnel que vous nous communiquez par
					le biais des formulaires disponibles sur ce site (par exemple une
						demande de contact ou de renseignement, une simulation,.) sont
						traitées par la banque, responsable de traitement, à des fins de
						gestion interne et pour répondre à votre demande.
				</p>
				<p>
					D’une manière générales, les informations vous expliquant de quelle
					manière vos données sont obtenues, pourquoi elles sont traitées, avec
					qui elles sont susceptibles d’être partagées, les mesures mises en oeuvre
					pour assurer leur confidentialité et leur sécurité figurent dans
					la Notice d’information sur les traitements des données à caractère
					personnel de Caisse d’Epargne. Elle rappelle également les droits dont
					vous disposez et comment les exercer.
				</p>
				<p>
					Pour en savoir plus sur notre notice d’information sur le traitement
					des données personnelles { ' ' }
					<a href="https://www.caisse-epargne.fr/cote-d-azur/protection-donnees-personnelles">https://www.caisse-epargne.fr/cote-d-azur/protection-donnees-personnelles</a>
				</p>
				<p>
					Spécifiquement pour ce site, l’Utilisateur doit communiquer à la Caisse
					d’Epargne Côte d’Azur
					des données à caractère personnel listées dans les formulaires pour accéder
					et pour utiliser
					le Service objet des présentes CGU. Ces données sont utilisées par la Caisse
					d’Epargne Côte d’Azur
					exclusivement à des fins de :<br />
					Répondre à la demande de l’utilisateur dans le cadre d’un financement de
					biens immobiliers
					Transmettre sa demande au promoteur pour lui apporter des informations
					supplémentaire sur
					le bien en question.<br />
					Ces données sont conservées par la Caisse d’Epargne Côte d’Azur pour la
					durée strictement nécessaire à la réalisation des finalités visées ci-dessus.
				</p>
				<p>
					Elles sont aussi traitées à des fins de prospection commerciale et
					elles ne sont pas communiquées
					ou cédées à des tiers, sous réserve de l’accord préalable de l’Utilisateur.
					Certaines données peuvent toutefois être adressées, à leur demande,
					aux autorités légalement
					habilitées, notamment dans le cadre de la lutte contre le blanchiment
					des capitaux ou de la
					lutte contre le financement du terrorisme.
				</p>
				<p>
					Conformément à la réglementation en vigueur, l’Utilisateur dispose d’un droit d’accès,
					de rectification et de suppression des informations qu’il a communiquées ainsi que
					d’un droit d’opposition au traitement des données qui le concernent et de limitation
					au traitement de ses données. L’Utilisateur est également informé qu’il dispose du droit
					de définir des directives permettant l’accès à ses données en cas de décès
					(les modalités d’exercice de ce droit sont actuellement en attente de
						précisions par décret).
					Les demandes portant sur ces droits peuvent être exercées directement en adressant un
					courrier électronique à : delegue-protection-donnees@cecaz.caisse-epargne.fr
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 10. Convention sur la preuve</h2>
				<p>
					Les Parties entendent fixer, dans le cadre du Service, les règles relatives
					aux preuves recevables entre eux en cas de litige et à leur force probante.
					Les stipulations qui suivent constituent ainsi la convention de preuve passée entre
					les parties, lesquelles s’engagent à respecter le présent article.
				</p>
				<p>
					Le Service procède aux enregistrements informatiques des connexions et des opérations
					réalisées au cours de son utilisation. Sont expressément concernés les cases cochées,
					les dépôts de pièces, les clics, les courriers électroniques adressés et
					reçus par la Banque,
					les accusés de réception émis et reçus par la Banque. Les parties reconnaissent que ces
					enregistrements de connexions, d’opérations et d’informations, courriers électroniques,
					accusés de réception, sont admissibles devant les tribunaux et font preuve des
					données et
					des éléments qu’ils contiennent et ce, quel que soit le support utilisé pour
					ces enregistrements.<br />
					La preuve contraire peut être rapportée.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 11. Intégralité des relations</h2>
				<p>
					Les Parties reconnaissent que les dispositions des Conditions Générales
					constituent l’intégralité des accords intervenus entre elles en ce qui concerne
					la réalisation de l’objet des présentes et annulent et remplacent tous accords ou
					propositions antérieures ayant le même objet, quelle qu’en soit la forme.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 12. Cessibilité des Conditions Générales</h2>
				<p>
					La Caisse d’Epargne Côte d’Azur se réserve la faculté de céder, transférer ou
					apporter à un tiers, sous quelque forme que ce soit, les droits et obligations
					nés des présentes CGU.
				</p>
			</LegalArticle>
			<LegalArticle>
				<h2>Article 13. Loi applicable et juridiction compétente</h2>
				<p>
					Les parties conviennent que la loi applicable est la loi Française,
					que les litiges sont de la compétence exclusive des tribunaux Français.
				</p>
				<p>
					A défaut de règlement amiable, en cas de litige relatif à l’interprétation,
					la formation ou l’exécution des Conditions générales d’utilisation et faute d’être
					parvenus à un accord amiable ou à une transaction, les Parties donnent compétence
					expresse et exclusive aux tribunaux compétents du ressort de la Cour d’appel d’Aix
					en Provence, nonobstant pluralité
					de défendeurs ou d’action en référé ou d’appel en garantie ou de mesure conservatoire.
				</p>
			</LegalArticle>
		</LegalSection>
	</section>
);

LegalCGU.propTypes = {
	appLegalEmail: PropTypes.string.isRequired,
	appWWW: PropTypes.string.isRequired,
};

export default React.memo(LegalCGU);
