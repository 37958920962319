import React from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Form } from 'react-jsonschema-form-validation';
import genericErrorMessages from '../../lib/error-messages';


export default ({ children, errorMessages, ...props }) => (
	<Form
		errorMessages={errorMessages || genericErrorMessages}
		{...props}
	>
		{children}
	</Form>
);
