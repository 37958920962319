import React from 'react';
import PropTypes from 'prop-types';

const formatNumber = (num) => {
	/*eslint-disable-next-line no-restricted-globals*/
	if (!num || isNaN(num)) return num;
	return num.toLocaleString(undefined, { maximumFractionDigits: 2 });
};

const RangeDisplay = (props) => {
	const { className, room } = props;
	let {
		min,
		max,
		textBefore,
		textFrom,
		textBetween,
		textAfter,
	} = props;

	textBefore = textBefore || 'De ';
	textFrom = textFrom || 'À partir de ';
	textBetween = textBetween || ' à ';
	textAfter = textAfter || '';

	min = formatNumber(min);
	max = formatNumber(max);

	let content = '';

	if (min && !max) {
		content = textFrom + min + textAfter;
	} else if (!min && max) {
		content = max + textAfter;
	} else if (min && max) {
		content = textBefore + min + textBetween + max + textAfter;
	}

	if (min === max && room) {
		content = min + textAfter;
	}

	return (
		<span className={`RangeDisplay ${className || ''}`}>
			{ content }
		</span>
	);
};

RangeDisplay.propTypes = {
	className: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	room: PropTypes.bool,
	textBefore: PropTypes.string,
	textFrom: PropTypes.string,
	textBetween: PropTypes.string,
	textAfter: PropTypes.string,
};

RangeDisplay.defaultProps = {
	className: '',
	min: 0,
	max: 0,
	room: false,
	textBefore: '',
	textFrom: '',
	textBetween: '',
	textAfter: '',
};

const RangeAreaDisplay = props => (
	<RangeDisplay
		textBefore="De "
		textBetween=" à "
		textAfter=" m²"
		{...props}
	/>
);

const RangePriceDisplay = props => (
	<RangeDisplay
		textBetween=" € à "
		textAfter=" €"
		{...props}
	/>
);

const RangeRoomsDisplay = props => (
	<RangeDisplay
		room
		textBefore="Du "
		textBetween=" au "
		textAfter=" pièces"
		{...props}
	/>
);


export default RangeDisplay;
export {
	RangeAreaDisplay,
	RangePriceDisplay,
	RangeRoomsDisplay,
};
