
import { parse } from 'query-string';

function getIntNumber(str) {
	return parseInt(str, 10) || undefined;
}

function getFloatNumber(str) {
	return parseFloat(str, 10) || undefined;
}

export function paramsToSearchData({ hash }) {
	const data = {};

	const params = parse(hash);

	if (params.location_label
		&& params.location_bounds
		&& params.location_value
		&& params.location_type) {
		const bounds = params.location_bounds.split(',');
		data.location = {
			bounds: {
				south: getFloatNumber(bounds[0]),
				west: getFloatNumber(bounds[1]),
				north: getFloatNumber(bounds[2]),
				east: getFloatNumber(bounds[3]),
			},
			value: JSON.parse(params.location_value),
			label: params.location_label,
			type: params.location_type,
		};
	}

	// lp
	if (params.locationZone) {
		data.locationZone = params.locationZone;
	}
	if (params.partner) {
		data.partner = params.partner;
	}

	if (params.pieces) {
		const rooms = params.pieces.split(',');
		data.minRooms = getIntNumber(rooms[0], 10);
		data.maxRooms = getIntNumber(rooms[1], 10);
	}

	if (params.prix) {
		const price = params.prix.split(',');
		data.minPrice = getIntNumber(price[0], 10);
		data.maxPrice = getIntNumber(price[1], 10);
	}

	return data;
}

export function searchDataToParams({
	location, minRooms, maxRooms, minPrice, maxPrice,
}) {
	const {
		bounds, label, type, value,
	} = location;
	const boundsString = `${bounds.south || ''},${bounds.west || ''},${bounds.north || ''},${bounds.east || ''}`;

	const roomsString = `${minRooms || ''},${maxRooms || ''}`;
	const priceString = `${minPrice || ''},${maxPrice || ''}`;

	const params = `location_label=${label
	}&location_type=${type
	}&location_value=${JSON.stringify(value)
	}&location_bounds=${boundsString
	}&pieces=${roomsString
	}&prix=${priceString}`;

	return params;
}
