import PropTypes from 'prop-types';
import React from 'react';

import './programme-lots.scss';

const ProgrammeLots = ({ lots, title }) => (
	<div className="ProgrammeLots">
		<h2 className="ProgrammeLots-title">{title}</h2>
		<table className="ProgrammeLots-table">
			<thead>
				<tr>
					<td>Pièces</td>
					<td>Surface</td>
					<td>Prix</td>
					<td>Parking</td>
					<td>Quantité</td>
				</tr>
			</thead>
			<tbody>
				{
					lots.map(lot => (
						<tr key={lot.id}>
							<td>{lot.rooms}</td>
							<td>{lot.area}</td>
							<td>{lot.price}</td>
							<td>{lot.parking ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
							<td>{lot.amount}</td>
						</tr>
					))
				}
			</tbody>
		</table>
	</div>
);

ProgrammeLots.propTypes = {
	lots: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			rooms: PropTypes.number,
			area: PropTypes.shape,
			price: PropTypes.number,
			parking: PropTypes.string,
			amount: PropTypes.number,
		}),
	).isRequired,
	title: PropTypes.string.isRequired,
};

export default ProgrammeLots;
