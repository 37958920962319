import PropTypes from 'prop-types';
import React from 'react';

import './article.scss';

const LegalArticle = ({ children }) => (
	<article className="LegalArticle">
		{ children }
	</article>
);

LegalArticle.propTypes = {
	children: PropTypes.node,
};

LegalArticle.defaultProps = {
	children: null,
};

export default LegalArticle;
