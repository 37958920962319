import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import Header from './header';

import './contact.scss';

const HeaderContact = (props) => {
	const match = useRouteMatch();
	const { trackEvent } = useMatomo();

	const clickContactButton = () => {
		trackEvent({
			category: 'Contact',
			action: 'Click',
			value: 'HEADER_CLICK_CONTACT',
		});
	};

	return (
		<Header {...props} fixed>
			<Link
				to={match.params.uuid ? `/contact/${match.params.uuid}` : '/contact/'}
				className="btn btn-error Header-contact"
				onClick={clickContactButton}
			>Contact
			</Link>
		</Header>
	);
};

export default HeaderContact;
