import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Field, FieldError } from 'react-jsonschema-form-validation';

import './field.scss';

const FormField = ({
	className,
	children,
	customFieldError,
	errorMessages,
	name,
	...props
}) => (
	<div className={`FormField ${className || ''}`}>
		<Field name={name} {...props} />
		{
			!customFieldError
			&& <FieldError name={name} errorMessages={errorMessages} />
		}
		{children}
	</div>

);

FormField.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	errorMessages: PropTypes.objectOf(PropTypes.func),
	name: PropTypes.string.isRequired,
	customFieldError: PropTypes.bool,
};

FormField.defaultProps = {
	children: null,
	className: '',
	customFieldError: false,
	errorMessages: null,
};

export default FormField;
