import React from 'react';
import PropTypes from 'prop-types';

import LocationSelect from '../location/location-select';
import {
	locationToGeometryBounds,
	LOCATION_TYPE_POSITION,
	LOCATION_TYPE_CITY,
	LOCATION_TYPE_DEP,
	LOCATION_TYPE_POSTAL_CODE,
	LOCATION_TYPE_BOUNDS,
} from '../../lib/location';

import Range from '../range/range';

import './search-programme.scss';

class SearchProgramme extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			location: props.location,
			minRooms: props.minRooms,
			maxRooms: props.maxRooms,
			minPrice: props.minPrice,
			maxPrice: props.maxPrice,
			loading: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			location: nextProps.location,
			minRooms: nextProps.minRooms,
			maxRooms: nextProps.maxRooms,
			minPrice: nextProps.minPrice,
			maxPrice: nextProps.maxPrice,
		});
	}

	onSubmit = (e) => {
		e.preventDefault();
		const {
			location,
			minRooms,
			maxRooms,
			minPrice,
			maxPrice,
		} = this.state;
		const { onSearch } = this.props;

		this.setState({ loading: true });

		const promise = new Promise((resolve, reject) => {
			const data = {};
			data.minRooms = minRooms;
			data.maxRooms = maxRooms;
			data.minPrice = minPrice;
			data.maxPrice = maxPrice;

			if (location) {
				data.location = Object.assign({}, location);
				switch (location.type) {
				case LOCATION_TYPE_POSITION:
					data.location.bounds = {
						south: location.value.lat - 0.01,
						west: location.value.lng - 0.01,
						north: location.value.lat + 0.01,
						east: location.value.lng + 0.01,
					};
					resolve(data);
					break;
				case LOCATION_TYPE_DEP:
				case LOCATION_TYPE_CITY:
				case LOCATION_TYPE_POSTAL_CODE:
					locationToGeometryBounds(location.value)
						.then((bounds) => {
							data.location.bounds = {
								south: bounds.viewport.southwest.lat,
								west: bounds.viewport.southwest.lng,
								north: bounds.viewport.northeast.lat,
								east: bounds.viewport.northeast.lng,
							};
							resolve(data);
						})
						.catch(reject);
					break;
				case LOCATION_TYPE_BOUNDS:
					// Should have been set with the map !
					resolve(data);
					break;
				default:
					reject(new Error('bad location type'));
				}
			} else {
				resolve(data);
			}
		});

		promise
			.catch((err) => {
				this.setState({ loading: false });
				throw err;
			})
			.then((data) => {
				this.setState({ loading: false });
				return data;
			})
			.then(onSearch);
	}

	onLocationChange = (location) => {
		// check if location has been cleared
		if (Object.entries(location.value).length === 0) {
			this.setState({ location: undefined });
		} else {
			this.setState({ location: location.value });
		}
	}

	handleChange = (event) => {
		const { target } = event;
		const { name } = target;

		let { value } = target;
		if (target.type === 'number') value = parseFloat(value, 10) || '';

		this.setState({
			[name]: value,
		});
	}

	render() {
		const { className, submitText } = this.props;
		const {
			loading,
			location,
			minPrice,
			maxPrice,
			minRooms,
		} = this.state;

		return (
			<form
				onSubmit={this.onSubmit}
				className={`SearchProgramme ${className || ''}`}
			>

				<div className="SearchProgramme-fieldset">
					<label className="SearchProgramme-label">Localisation</label>
					<span className="SearchProgramme-text">Je cherche un logement à </span>
					<LocationSelect
						className=""
						onChange={this.onLocationChange}
						isClearable
						value={location}
					/>
				</div>

				<div className="SearchProgramme-fieldset">
					<label className="SearchProgramme-label">Budget</label>
					<span className="SearchProgramme-text">Votre budget </span>
					<span className="SearchProgramme-input-text">entre </span>
					<Range
						type="range"
						max={2000000}
						min={0}
						step={50000}
						suffix=" €"
						value={minPrice}
						name="minPrice"
						onChange={this.handleChange}
						className="minBudget"
						placeholder="Min budget"
					/>
					<span className="SearchProgramme-input-text input-spacement"> et </span>
					<Range
						type="range"
						max={4000000}
						min={0}
						step={50000}
						suffix=" €"
						value={maxPrice}
						name="maxPrice"
						onChange={this.handleChange}
						className="maxBudget"
						placeholder="Max budget"
					/>
				</div>

				<div className="SearchProgramme-fieldset">
					<label className="SearchProgramme-label">Pièces</label>
					<span className="SearchProgramme-text">Nombre de pièces</span>
					<span className="SearchProgramme-input-text" />
					<Range
						type="range"
						max={10}
						min={0}
						step={1}
						value={minRooms}
						name="minRooms"
						onChange={this.handleChange}
						className="minRooms"
						placeholder="Min Pièces"
					/>
					<span className="SearchProgramme-input-text" />
				</div>

				<div className="SearchProgramme-fullline">
					<button
						className="SearchProgramme-submit"
						type="submit"
						disabled={loading || !location}
					>
						{
							loading
								? <span className="fa fa-spinner spinner" />
								: (submitText || 'Trouver')
						}
					</button>
				</div>
			</form>
		);
	}
}

SearchProgramme.propTypes = {
	onSearch: PropTypes.func.isRequired,
	location: PropTypes.shape({}),
	minRooms: PropTypes.number,
	maxRooms: PropTypes.number,
	minPrice: PropTypes.number,
	maxPrice: PropTypes.number,
	className: PropTypes.string,
	submitText: PropTypes.string,
};

SearchProgramme.defaultProps = {
	className: '',
	location: undefined,
	minRooms: undefined,
	maxRooms: undefined,
	minPrice: undefined,
	maxPrice: undefined,
	submitText: '',
};

export default SearchProgramme;
