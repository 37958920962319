import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-rangeslider';

import './range.scss';

/*eslint-disable no-restricted-globals*/
function number(value) {
	return isNaN(value) ? undefined : value;
}

function numberToString(value) {
	if (isNaN(value)) return '';
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

class Range extends React.Component {
	constructor(props) {
		super(props);
		const { value } = this.props;
		this.state = {
			value,
		};
	}

	static getDerivedStateFromProps(props, state) {
		if (props.value !== state.value) {
	      return {
	        value: state.value,
	      };
	    }
		return null;
	}

	onChange = () => {
		const { name, onChange } = this.props;
		const { value } = this.state;
		onChange({
			target: {
				type: 'range',
				name,
				value,
			},
		});
	}

	handleChange = (value) => {
		this.setState({
			value,
		});
	}

	render() {
		let { value } = this.state;
		const {
			min,
			max,
			step,
		} = this.props;
		let { suffix } = this.props;
		value = number(value);
		suffix = suffix || '';

		return (
			<div className="Range">
				<span className="Range-value">{ isNaN(value) ? '-' : (numberToString(value) + suffix) }</span>
				<Slider
					min={min}
					max={max}
					step={step}
					value={value}
					onChange={this.handleChange}
					onChangeComplete={this.onChange}
					tooltip={false}
					className="Range-Slider"
				/>
			</div>
		);
	}
}

Range.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	step: PropTypes.number,
	suffix: PropTypes.string,
	value: PropTypes.number,
};

Range.defaultProps = {
	min: 0,
	max: 0,
	name: '',
	step: 0,
	suffix: '',
	value: undefined,
};

export default Range;
/*eslint-enable no-restricted-globals*/
