import Rollbar from 'rollbar';

const {
	REACT_APP_BUILD_ID,
	REACT_APP_ROLLBAR_ACCESS_TOKEN,
	REACT_APP_VERSION,
	REACT_APP_STAGE,
} = process.env;

// eslint-disable-next-line camelcase
const code_version = `${REACT_APP_VERSION}/${REACT_APP_BUILD_ID}`;

const rollbar = new Rollbar({
	accessToken: REACT_APP_ROLLBAR_ACCESS_TOKEN,
	captureUncaught: true,
	captureUnhandledRejections: true,
	enabled: (REACT_APP_STAGE === 'production' || REACT_APP_STAGE === 'staging'),
	payload: {
		client: { javascript: { code_version } },
		environment: REACT_APP_STAGE,
	},
});

global.rollbar = rollbar;

export default rollbar;
