export class RequestError extends Error {
	constructor(message, status) {
		super(message);
		// Capturing stack trace, excluding constructor call from it.
		Error.captureStackTrace(this, this.constructor);
		this.name = this.constructor.name;
		this.status = status || 500;
		if (global.Rollbar) {
			global.rollbar.error(`${this.name} + ${this.message}`);
		}
	}

	getJson() {
		return {
			error: this.name,
			message: this.message,
		};
	}
}

export const fetchStatusHandler = (response) => {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}

	throw new RequestError(response.statusText, response.status);
};

export const request = (url, opts) => fetch(url, opts)
	.then(fetchStatusHandler);

export const checkNotFoundError = (error) => {
	if (error && error.status === 404) return;
	throw error;
};
