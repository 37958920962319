import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactSelect from 'react-select';
import classnames from 'classnames';

import './Select.scss';

class FormSelect extends PureComponent {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(value) {
		const {
			formatValue,
			isMulti,
			name,
			onChange,
		} = this.props;

		const event = {
			target: {
				name,
				value: isMulti
					? (value || []).map(formatValue)
					: value && formatValue(value),
			},
		};

		onChange(event);
	}

	render() {
		const {
			className,
			component: Component,
			formatValue,
			placeholder,
			...props
		} = this.props;
		return (
			<Component
				{...props}
				className={classnames('FormSelect', className)}
				classNamePrefix="FormSelect"
				loadingMessage={() => "Chargement..."}
				noOptionsMessage={() => "Taper votre recherche"}
				onChange={this.handleChange}
				placeholder={placeholder}
			/>
		);
	}
}

FormSelect.propTypes = {
	className: PropTypes.string,
	component: PropTypes.func,
	formatValue: PropTypes.func,
	isMulti: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
};

FormSelect.defaultProps = {
	className: '',
	component: ReactSelect,
	formatValue: value => value,
	isMulti: false,
	onChange: () => {},
	placeholder: 'Sélectionner',
};

export default FormSelect;
