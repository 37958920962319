import React from 'react';

import CustomMap from '../map/map';
import Marker from '../map/marker';
import InfoWindow from '../map/info-window';
import ProgrammeList from '../programme/programme-list';
import ProgrammeListItem from '../programme/programme-list-item';

import './search-results.scss';

function debounce(func, wait, immediate) {
	let timeout;
	return (...args) => {
		const context = this;
		const later = () => {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export default class SearchResults extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			show: 'both',
		};
	}

	componentDidMount() {
		window.addEventListener('resize', debounce(() => { this.updateWindowDimensions(); }, 250));
		this.updateWindowDimensions();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	onClickSwitchMapList = () => {
		const { show } = this.state;
		this.setState({ show: show === 'map' ? 'list' : 'map' });
	}

	updateWindowDimensions = () => {
		const { show } = this.state;
		let showMode = 'both';
		if (window.innerWidth < 1000) {
			showMode = show === 'map' ? 'map' : 'list';
		}
		this.setState({show: showMode });
	}

	render() {
		const { show } = this.state;
		const {
			onMapBoundsChange,
			onClickProgrammeListItem,
			programmes,
			search,
		} = this.props;

		return (
			<section className="SearchResults">
				{
					(show === 'both' || show === 'list')
					&&	(
						<ProgrammeList
							className="SearchResults-ProgrammeList cols-2"
							onClick={onClickProgrammeListItem}
							programmes={programmes}
						/>
					)
				}
				<CustomMap
					bounds={search.location && search.location.bounds}
					className="SearchResults-CustomMap"
					show={show}
					onBoundsChange={onMapBoundsChange}
					zoom={8}
				>
					{
						programmes.map(programme => (
							<Marker position={programme} key={programme.id}>
								<InfoWindow>
									<div onClick={() => { onClickProgrammeListItem(programme); }}>
										<ProgrammeListItem
											programme={programme}
										/>
									</div>
								</InfoWindow>
							</Marker>
						))
					}
				</CustomMap>
				{
					show !== 'both'
					&& (
						<div className="SearchResults-floatingMapOrList">
							<button type="button" className="btn" onClick={this.onClickSwitchMapList}>
								{
									show === 'map'
										? <span><span>Liste</span><i className="fa fa-bars" /></span>
										: <span><span>Carte</span><i className="fa fa-map" /></span>
								}
							</button>
						</div>
					)
				}
			</section>
		);
	}
}
