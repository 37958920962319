import Helmet from 'react-helmet';
import React from 'react';

import LegalCGU from '../components/legal/cgu';
import Page from '../components/page/Page';

const {
	REACT_APP_EMAIL_PUBLIC,
	REACT_APP_WWW,
} = process.env;

export default () => (
	<Page className="PageLegal">
		<Helmet>
			<title>cherchebienneuf.fr – Conditions générales d’utilisation</title>
		</Helmet>
		<section className="page-center section last-section">
			<LegalCGU appWWW={REACT_APP_WWW} appLegalEmail={REACT_APP_EMAIL_PUBLIC} />
		</section>
	</Page>
);
