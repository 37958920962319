import Helmet from 'react-helmet';
import React from 'react';

import PolitiqueCookies from '@cecaz-immo/ui/dist/components/legal/politique-cookies';
import Page from '../components/page/Page';

const {
	REACT_APP_EMAIL_PUBLIC,
	REACT_APP_URL,
	REACT_APP_WWW,
} = process.env;

export default () => (
	<Page className="PageCookies">
		<Helmet>
			<title>{REACT_APP_WWW} – Politique de cookies</title>
		</Helmet>
		<section className="page-center section last-section">
			<PolitiqueCookies
				appLegalEmail={REACT_APP_EMAIL_PUBLIC}
				appUrl={REACT_APP_URL}
				appWWW={REACT_APP_WWW}
			/>
		</section>
	</Page>
);
