import React from 'react';

import TextToggle from '@cecaz-immo/ui/dist/components/text/toggle';
import './mentions.scss';

const FormMentions = () => {
	const visibleText = `La Caisse d’Epargne Côte d’Azur recueille des données à caractère personnel vous concernant et
met en œuvre des mesures techniques et organisationnelles appropriées pour s’assurer que les
traitements de données à caractère personnel sont effectués conformément à la législation
applicable.
Certaines de ces données sont indiquées comme étant obligatoires. A défaut votre demande ne
pourrait pas être traitée ou son traitement s’en trouverait retardé. Ces données sont
signalées par un *.`;

	const toggleText = (
		<p>
			Vos données sont traitées pour les finalités suivantes :<br />
				- Gestion de votre demande
			<br />
			<br />
			Vos données sont destinées à la Caisse d’Epargne Cote d’Azur, responsable(s) de traitement.
			Durée de conservation : la durée de conservation des données est de : 3 mois.
			Pendant une durée de 3 mois pour les finalités liées à la gestion de votre demande.
			<br />
			Vos données sont transmises également au Promoteur pour le traitement de votre demande.
			<br /><br />
			Exercice des droits :
			<br />
			Vous bénéficiez d’un droit d’accès à vos données à caractère personnel. Dans les
			conditions prévues par la loi, vous pouvez également demander une limitation du traitement,
			la rectification ou l’effacement des données vous concernant, ainsi que leur portabilité,
			ou communiquer des directives sur le sort de ces données en cas de décès.
			Si le traitement est fondé sur votre consentement, vous disposez du droit de retirer votre
			consentement.<br /><br />
			Si le traitement est fondé sur l’intérêt légitime de la Caisse d’Epargne Côte d’Azur,
			vous pouvez vous opposer à ce traitement si vous justifiez de raisons propres à
			votre situation. Vous disposez également du droit de vous opposer au traitement de vos données
			à des fins de prospection commerciale. Ces droits peuvent, sous réserve de justifier de votre
			identité par la production d’une copie d’identité, être exercés à tout moment à l’adresse
			suivante :
			<br /><br />
				- Par courrier postal :<br />
				Service Relations Clientèle 455 Promenade des Anglais – BP 3297 – 06205 NICE Cedex 3
			<br />
				- Par courriel: Formulaire de contact du Service Relation Clientèle :
			<br />
			<a href="https://www.caisse-epargne.fr/cote-d-azur/service-relations-clientele-des-particuliers">https://www.caisse-epargne.fr/cote-d-azur/service-relations-clientele-des-particuliers</a>
			<br />
			<br />
				Si vous souhaitez en savoir plus ou contacter notre Délégué à
				la Protection des Données, vous
				pouvez nous écrire à l’adresse suivante :<br />
				Délégué à la Protection des Données<br />
				Caisse d’Epargne Côte d’Azur<br />
				455 Promenade des Anglais<br />
				BP 3297 – 06205 NICE Cedex 3<br />
				Ou par mail : <a href="mailto:delegue-protection-donnees@cecaz.caisse-epargne.fr">delegue-protection-donnees@cecaz.caisse-epargne.fr</a>
			<br /><br />
				Réclamations : Les personnes concernées ont le droit
				d’introduire une réclamation auprès d’une autorité de
				contrôle en charge de la protection des personnes physiques
				à l’égard du traitement des données à caractère personnel.
				En France, l’autorité de contrôle est : Commission Nationale
				de l’Informatique et des Libertés (CNIL) 3 place de Fontenoy
				TSA 80715 75334 PARIS Cedex 07.
			<br /><br />
				Pour plus d’information, consultez notre notice d’information sur la protection des données
				personnelles <a href="https://www.caisse-epargne.fr/cote-d-azur/protection-donnees-personnelles">https://www.caisse-epargne.fr/cote-d-azur/protection-donnees-personnelles</a>
			<br /><br />
		</p>
	);
	return (
		<section className="mentions">
			<TextToggle visibleText={visibleText} toggleText={toggleText} />
		</section>
	);
};

export default FormMentions;
