import React from 'react';
import PropTypes from 'prop-types';
import SelectAsync from '@cecaz-immo/ui/dist/components/form/select/Async';

import * as Location from '../../lib/location';
import MyLocation from './my-location';

import './location-select.scss';

const getOptions = async (input) => {
	if (!input || input.length < 2) {
		return [];
	}

	const locations = await Location.findLocations(input).then((data) => ({
		location: data.location,
		results: data.results.map((res) => ({
			type: res.type,
			records: res.records.filter((record, index, self) => self.findIndex((item) => {
				switch (res.type) {
				case Location.LOCATION_TYPE_POSTAL_CODE:
					return item.code_postal === record.code_postal;
				case Location.LOCATION_TYPE_CITY:
					return (item.code_com === record.code_com);
				case Location.LOCATION_TYPE_DEP:
					return (item.nom === record.nom);
				default:
					return (item.nom === record.nom);
				}
			}) === index),
		})),
	}));

	let options = locations.results.map((location) => (
		location.records.map((record) => {
			let value = {};
			let label = null;

			switch (location.type) {
			case Location.LOCATION_TYPE_POSTAL_CODE:
				value = record.code_postal;
				label = `${record.code_postal} (${record.nom_com})`;
				break;
			case Location.LOCATION_TYPE_CITY:
				value = record.nom_de_la_commune;
				label = record.nom_com;
				break;
			case Location.LOCATION_TYPE_DEP:
				value = record.nom;
				label = record.nom.toUpperCase();
				break;
			default:
			}

			return {
				type: location.type,
				value,
				label,
			};
		})
	));
	options = options.flat();
	return options;
};
const color = '#db0029';
const cecazStyle = {
	control: (styles) => ({
		...styles,
		backgroundColor: 'transparent',
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		borderColor: color,
		borderLeft: 0,
		borderRight: 0,
		borderTop: 0,
		boxShadow: '0px 0px 3px transparent',
		width: '230px',
		'&:hover': {
			boxShadow: '0px 0px 3px transparent',
		},
	}),
	dropdownIndicator: () => ({
		display: 'none',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	placeholder: (styles) => ({
		...styles,
		color,
		fontSize: '1.4rem',
	}),
	singleValue: () => ({
		color,
	}),
};

class LocationSelect extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			location: props.value,
		};

		this.onGeolocation = this.onGeolocation.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	componentWillReceiveProps({ value }) {
		if (value) {
			this.setState({ location: value });
		} else {
			this.setState({ location: '' });
		}
	}

	onGeolocation({ coords }) {
		const location = {
			name: 'location',
			value: {
				type: Location.LOCATION_TYPE_POSITION,
				label: 'Ma position',
				value: {
					lat: coords.latitude,
					lng: coords.longitude,
				},
			},
		};

		this.handleChange(location);
	}

	handleChange(location) {
		this.setState({ location });
		const { onChange } = this.props;

		if (location.value === null) {
			const clearLocation = {
				name: location.name,
				value: '',
			};
			location = clearLocation;
		}

		if (onChange) {
			onChange(location);
		}
	}

	render() {
		const {
			className,
			geolocation,
			isSelectAdmin,
			isClearable,
		} = this.props;
		const { location } = this.state;

		// See https://github.com/JedWatson/react-select/issues/2025#issuecomment-378306411
		// (joinValues)
		return (
			<div className={`LocationSelect ${className}`}>
				<SelectAsync
					cache={false} // ==> getOptions is now called every new inputs
					className="Select"
					filterOption={() => (true)}
					isClearable={isClearable}
					joinValues
					loadOptions={getOptions}
					name="location"
					onChange={({ target }) => this.handleChange(target)}
					placeholder="Ville ou code postal"
					styles={!isSelectAdmin && cecazStyle}
					value={location}
				/>
				{
					geolocation
					&& <MyLocation className="Select" onGeolocation={this.onGeolocation} />
				}
			</div>
		);
	}
}

LocationSelect.propTypes = {
	className: PropTypes.string,
	geolocation: PropTypes.bool,
	isSelectAdmin: PropTypes.bool,
	isClearable: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.shape({
					lat: PropTypes.number.isRequired,
					lng: PropTypes.number.isRequired,
				}),
				PropTypes.string,
			]),
		}),
		PropTypes.string,
	]),
};

LocationSelect.defaultProps = {
	className: '',
	geolocation: true,
	isClearable: false,
	isSelectAdmin: false,
	value: null,
};

export default LocationSelect;
