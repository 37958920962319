import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'isomorphic-fetch'; //fetch

global.isIEcancer = !!window.MSInputMethodContext && !!document.documentMode;


// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function capitalize() {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

/* eslint-disable */

// flat
if (!Array.prototype.flat) {
	Object.defineProperties(Array.prototype, {
		flat: {
			configurable: true,
			value: function flat() {
				let depth = isNaN(arguments[0]) ? 1 : Number(arguments[0]);
				const stack = Array.prototype.slice.call(this);
				const result = [];

				while (depth && stack.length) {
					const next = stack.pop();

					if (Object(next) instanceof Array) {
						--depth;

						Array.prototype.push.apply(stack, next);
					} else {
						result.unshift(next);
					}
				}

				return result.concat(stack);
			},
			writable: true
		},
		flatMap: {
			configurable: true,
			value: function flatMap(callback) {
				return Array.prototype.map.apply(this, arguments).flat();
			},
			writable: true
		}
	});
}

// isInteger
Number.isInteger = Number.isInteger || function isInteger(value) {
	// eslint-disable-next-line no-restricted-globals
	return typeof value === 'number' && isFinite(value)
		&& Math.floor(value) === value;
};

// includes String
if (!String.prototype.includes) {
	// eslint-disable-next-line no-extend-native
	String.prototype.includes = function includesPolyfill(search, start) {
		if (typeof start !== 'number') {
			start = 0;
		}
		if (start + search.length > this.length) {
			return false;
		}
		return this.indexOf(search, start) !== -1;
	};
}

// includes Array
if (!Array.prototype.includes) {
	// eslint-disable-next-line no-extend-native
	Object.defineProperty(Array.prototype, 'includes', {
		value: function valuesFunc(searchElement, fromIndex) {
			// 1. Let O be ? ToObject(this value).
			if (this == null) {
				throw new TypeError('"this" is null or not defined');
			}

			const o = Object(this);

			// 2. Let len be ? ToLength(? Get(O, "length")).
			const len = o.length >>> 0;

			// 3. If len is 0, return false.
			if (len === 0) {
				return false;
			}

			// 4. Let n be ? ToInteger(fromIndex).
			//    (If fromIndex is undefined, this step produces the value 0.)
			const n = fromIndex | 0;

			// 5. If n ≥ 0, then
			//  a. Let k be n.
			// 6. Else n < 0,
			//  a. Let k be len + n.
			//  b. If k < 0, let k be 0.
			let k = Math.max(
				n >= 0
					? n
					: len - Math.abs(n),
				0,
			);

			function sameValueZero(x, y) {
				return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
			}

			// 7. Repeat, while k < len
			while (k < len) {
				// a. Let elementK be the result of ? Get(O, ! ToString(k)).
				// b. If SameValueZero(searchElement, elementK) is true, return true.
				// c. Increase k by 1.
				if (sameValueZero(o[k], searchElement)) {
					return true;
				}
				k++;
			}

			// 8. Return false
			return false;
		},
	});
}

// parseInt
if (Number.parseInt === undefined) {
	Number.parseInt = window.parseInt;
}
// some
if (!Array.prototype.some) {
	// eslint-disable-next-line no-extend-native
	Array.prototype.some = function arraySome(fun/*, thisArg*/) {
		if (this == null) {
			throw new TypeError('Array.prototype.some called on null or undefined');
		}

		if (typeof fun !== 'function') {
			throw new TypeError();
		}

		const t = Object(this);
		const len = t.length >>> 0;

		const thisArg = arguments.length >= 2 ? arguments[1] : void 0;
		for (let i = 0; i < len; i++) {
			if (i in t && fun.call(thisArg, t[i], i, t)) {
				return true;
			}
		}
		return false;
	};
}

// Object.values
Object.values = Object.values ? Object.values : function (obj) {
	const allowedTypes = ['[object String]', '[object Object]', '[object Array]', '[object Function]'];
	const objType = Object.prototype.toString.call(obj);

	if (obj === null || typeof obj === 'undefined') {
		throw new TypeError('Cannot convert undefined or null to object');
	} else if (!~allowedTypes.indexOf(objType)) {
		return [];
	} else {
		// if ES6 is supported
		if (Object.keys) {
			return Object.keys(obj).map(key => obj[key]);
		}

		const result = [];
		for (const prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				result.push(obj[prop]);
			}
		}

		return result;
	}
};

// CustomEvent polyfill for carousel https://github.com/reactstrap/reactstrap/issues/799
(function () {
	function CustomEvent(event, params) {
		params = params || { bubbles: false, cancelable: false, detail: undefined };
		var evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}

	CustomEvent.prototype = window.Event.prototype;

	window.CustomEvent = CustomEvent;
})();
