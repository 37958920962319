import PropTypes from 'prop-types';
import React from 'react';

import './toogle.scss';

class TextToggle extends React.Component {
	constructor() {
		super();

		this.state = {
			isOpen: false,
		};

		this.toggleMentions = this.toggleMentions.bind(this);
	}

	toggleMentions() {
		const { isOpen } = this.state;
		this.setState({
			isOpen: !isOpen,
		});
	}

	render() {
		const { toggleText, visibleText } = this.props;
		const { isOpen } = this.state;
		return (
			<div className="TextToggle">
				<p>
					{visibleText}
				</p>
				{
					isOpen
						? (
							<>
								{toggleText}
								<button type="button" onClick={this.toggleMentions}>Voir moins {'<'}</button>
							</>
						)
						: <button type="button" onClick={this.toggleMentions}>Voir plus {'>'}</button>
				}
			</div>
		);
	}
}

TextToggle.propTypes = {
	toggleText: PropTypes.node.isRequired,
	visibleText: PropTypes.string.isRequired,
};

export default TextToggle;
