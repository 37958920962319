import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getFileUrl } from '../../lib/file';

// Components
import CustomMap from '../map/map';
import Marker from '../map/marker';
import Media from '../media/media';
import ProgrammeLots from './programme-lots';
import {
	RangeAreaDisplay,
	RangePriceDisplay,
	RangeRoomsDisplay,
} from '../range/range-display';
import Slider from '../slider/slider';
import SliderThumbnail from '../slider/slider-thumbnail';
import { calculatePricesAndRoomsProgramme } from '../../store/actions/programme';

import './programme-description.scss';

class ProgrammeDescription extends React.Component {
	constructor(props) {
		super(props);

		//init state
		this.state = Object.assign({
			index: 0,
			direction: 'left',
			commentary: '',
			loading: false,
			displayWarningMessage: false,
			message: '',
		}, this.createProgrammesState(props));

		// this.createEvent = this.createEvent.bind(this);
		this.memoPriceRoomsProgramme = memoize(calculatePricesAndRoomsProgramme);
	}

	componentWillReceiveProps(nextProps) {
		this.setState(this.createProgrammesState(nextProps));
	}

	// eslint-disable-next-line class-methods-use-this
	createProgrammesState(props) {
		const state = {
			apartments: [],
			houses: [],
			plots: [],
			commercials: [],
			status: props.programme.status,
		};
		// eslint-disable-next-line
		const lots = props.programme.lots;

		if (!lots || !lots.length) {
			return state;
		}

		props.programme.lots.forEach((lot, index) => {
			const data = {
				id: index,
				rooms: (lot.rooms === 0) ? 'studio' : lot.rooms,
				area: <RangeAreaDisplay min={lot.minArea} max={lot.maxArea} />,
				price: <RangePriceDisplay min={lot.minPrice} max={lot.maxPrice} />,
				parking: lot.containParking,
				amount: lot.amount,
			};

			switch (lot.type) {
			case 'COMMERCIAL':
				state.commercials.push(data);
				break;
			case 'PLOT':
				state.plots.push(data);
				break;
			case 'HOUSE':
				state.houses.push(data);
				break;
			case 'FLOAT':
			default:
				state.apartments.push(data);
			}
		});

		return state;
	}

	changeSlide(num) {
		const { index } = this.state;
		const direction = index < num ? 'left' : 'right';
		this.setState({ index, direction });
	}

	// eslint-disable-next-line class-methods-use-this
	// createEvent(label) {
	// 	ReactGA.event({
	// 		category: 'Contact',
	// 		action: 'Click',
	// 		label,
	// 	});
	// }

	render() {
		const { displayMode, programme, disableButtonMoreInfos } = this.props;
		const {
			index,
			direction,
			apartments,
			houses,
			plots,
			commercials,
		} = this.state;
		const files = programme.files || [];
		const mainImage = (files.length && getFileUrl(files[0])) || '';
		this.memoPriceRoomsProgramme(programme);

		return (
			<section className="ProgrammeDescription">
				<div
					className="ProgrammeDescription-main-image"
					style={{
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
						backgroundRepeat: 'no-repeat',
						backgroundImage: `url("${mainImage}")`,
					}}
				/>

				<div className="page-center">
					<div className="ProgrammeDescription-main">
						<h1 className="ProgrammeDescription-name">{programme.name}</h1>
						<address className="ProgrammeDescription-field ProgrammeDescription-address">
							{programme.district}
						</address>
						<strong className="ProgrammeDescription-field">
							<RangePriceDisplay min={programme.minPrice} max={programme.maxPrice} />
						</strong>
						<strong className="ProgrammeDescription-field">
							<RangeRoomsDisplay min={programme.minRooms} max={programme.maxRooms} />
						</strong>
						<span className="ProgrammeDescription-label">Livraison</span>
						<strong className="ProgrammeDescription-field">
							{programme.quarterDelivery}ème trimestre {programme.yearDelivery}
						</strong>
					</div>
				</div>

				<div className="ProgrammeDescription-section page-center text-center">
					{
						(
							displayMode
							&& (
								<button type="button" className="btn btn-error" disabled={disableButtonMoreInfos}>
									Obtenir des informations sur ce programme
								</button>
							)
						) || (
							<Link
								to={`/contact/${programme.uuid}`}
								className="btn btn-error"
								// onClick={() => { this.createEvent('PROGRAMME_CLICK_TOP_CONTACT'); }}
							>
								Obtenir des informations sur ce programme
							</Link>
						)
					}
				</div>

				<section
					className="ProgrammeDescription-section page-center programme-description"
					dangerouslySetInnerHTML={{
						__html: (programme.description || '').replace(/\n/g, '<br>'),
					}}
				/>

				<section className="ProgrammeDescription-section page-center">
					<Slider index={index} direction={direction}>
						{ files.map((file) => (
							<div className="slider-centered-img ProgrammeDescription-Slider-img" key={file.id}>
								<Media file={file} alt="illustration du programme" />
							</div>
						))}
					</Slider>
					<div className="ProgrammeDescription-Slider-thumbs">
						{ files.map((file, i) => (
							<SliderThumbnail
								key={file.id}
								file={file}
								onClick={() => { this.changeSlide(i); }}
							/>
						))}
					</div>
				</section>

				<div className="ProgrammeDescription-section page-center">
					{ (apartments && !!apartments.length)
						&& 	<ProgrammeLots lots={apartments} title="Lots appartements" />}
					{ (houses && !!houses.length)
						&& <ProgrammeLots lots={houses} title="Lots maisons" />}
					{ (plots && !!plots.length)
						&& <ProgrammeLots lots={plots} title="Lots terrains bâtis" />}
					{ (commercials && !!commercials.length)
						&& <ProgrammeLots lots={commercials} title="Lots locaux commerciaux" />}
				</div>

				<section className="ProgrammeDescription-location">
					<h2 className="section-title">Localisation</h2>
					<CustomMap cameraLocation={programme} zoom={14} static>
						<Marker position={programme} />
					</CustomMap>
				</section>

				<div className="ProgrammeDescription-section page-center text-center">
					{
						(
							displayMode
							&& (
								<button type="button" className="btn btn-error" disabled={disableButtonMoreInfos}>
									Obtenir des informations sur ce programme
								</button>
							)
						)
						|| (
							<Link
								to={`/contact/${programme.uuid}`}
								className="btn btn-error"
								// onClick={() => { this.createEvent('PROGRAMME_CLICK_BOTTOM_CONTACT'); }}
							>
								Obtenir des informations sur ce programme
							</Link>
						)
					}
				</div>
			</section>
		);
	}
}

ProgrammeDescription.propTypes = {
	displayMode: PropTypes.bool,
	disableButtonMoreInfos: PropTypes.bool,
	programme: PropTypes.shape({
		description: PropTypes.string,
		district: PropTypes.string,
		files: PropTypes.arrayOf({}),
		name: PropTypes.string,
		minPrice: PropTypes.number,
		maxPrice: PropTypes.number,
		minRooms: PropTypes.number,
		maxRooms: PropTypes.number,
		quarterDelivery: PropTypes.number,
		uuid: PropTypes.string,
		yearDelivery: PropTypes.string,
	}).isRequired,
	// updateProgrammeStatus: PropTypes.func,
};

ProgrammeDescription.defaultProps = {
	displayMode: false,
	disableButtonMoreInfos: false,
	// updateProgrammeStatus: undefined,
};

export default ProgrammeDescription;
