import CopyToClipboard from 'react-copy-to-clipboard';
import React, { PureComponent } from 'react';

import './copy-button.scss';


class CopyButton extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			copied: false,
			value: window.location.href,
		};

		this.handleClick = this.handleClick.bind(this);
	}

	componentWillUnmount() {
		window.clearTimeout(this.timeout);
	}

	handleClick() {
		this.setState({ copied: true });
		this.timeout = window.setTimeout(() => {
			this.setState({ copied: false });
		}, 3500);
	}

	render() {
		const { copied, value } = this.state;
		return (
			<div className="CopyButton-container">
				<CopyToClipboard
					className="CopyButton"
					onCopy={this.handleClick}
					text={value}
				>
					<button type="button" title="Copier l'adresse du programme">
						<i className="fa fa-copy" />
					</button>
				</CopyToClipboard>
				{
					copied && <span className="CopyButton-info">Lien du programme copié</span>
				}
			</div>
		);
	}
}

export default CopyButton;
