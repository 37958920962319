import React from 'react';
import PropTypes from 'prop-types';

import './Page.scss';

const Page = ({ children, className }) => (
	<div className={`Page ${className}`}>
		{ children}
	</div>
);

Page.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};


Page.defaultProps = {
	className: '',
};


export default Page;
