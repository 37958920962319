import React, { useEffect } from 'react';
import {
	Route, Switch, useLocation,
} from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import Footer from './components/footer/footer';
import HeaderContact from './components/header/contact';
// import LogPageView from './components/log-page-view/log-page-view';

import CGU from './pages/cgu';
import Contact from './pages/contact';
import Cookies from './pages/cookies';
import Home from './pages/home';
import Legal from './pages/legal';
import LoiPinel from './pages/loi-pinel';
import NotFoundRoute from './pages/404';
import Programme from './pages/programme/programme';
import ProgrammeSearch from './pages/programme/search';
import PretTauxZero from './pages/ptz';

const ScrollToTop = ({ children }) => {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [pathname]);

	return children || null;
};

// eslint-disable-next-line import/prefer-default-export
export const Routes = () => {
	const location = useLocation();
	const { trackPageView } = useMatomo();
	useEffect(() => {
		trackPageView({
			documentTitle: location.pathname,
		});
	});

	return (
		<ScrollToTop>
			<Route component={ScrollToTop} />

			<Switch>
				{/* Use of params.uuid in programme page */}
				<Route path="/programme/:name/p/:uuid" component={HeaderContact} />
				<Route component={HeaderContact} />
			</Switch>
			<Switch>
				<Route
					path="/search"
					component={ProgrammeSearch}
				/>
				<Route path="/programme/:name/p/:uuid" component={Programme} />
				<Route path="/contact/:uuid?" component={Contact} />
				<Route path="/politique-cookies" component={Cookies} />
				<Route path="/conditions-generales-utilisation" component={CGU} />
				<Route path="/mentions-legales" component={Legal} />
				<Route path="/loi-pinel" component={LoiPinel} />
				<Route path="/pret-taux-zero" component={PretTauxZero} />
				<Route exact path="/" component={Home} />
				<Route
					path="/developer"
					component={() => {
						window.location = 'https://pro.cherchebienneuf.fr';
						return null;
					}}
				/>
				<Route path="*" component={NotFoundRoute} />
			</Switch>
			<Footer />
		</ScrollToTop>
	);
};
