import PropTypes from 'prop-types';
import React from 'react';

import { MapContext } from './map';
import markerIcon from '../../images/marker.png';

export const MarkerContext = React.createContext({});

let id = 1;
const { google } = global;

class Marker extends React.Component {
	constructor(props) {
		super(props);

		this.closeInfoWindow = this.closeInfoWindow.bind(this);

		this.state = {
			// eslint-disable-next-line react/no-unused-state
			closeInfoWindow: this.closeInfoWindow,
			// eslint-disable-next-line react/no-unused-state
			markerId: id += 1,
			marker: undefined,
		};
	}

	componentWillMount() {
		const { map, position } = this.props;
		const p = (position
			&& position.lat
			&& position.lng) ? position : undefined;

		const icon = {
			url: markerIcon,
			size: new google.maps.Size(32, 32),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(16, 16),
		};

		const marker = new google.maps.Marker({
			position: p,
			icon,
			map,
			optimized: false,
		});

		const self = this;
		marker.addListener('click', (event) => {
			event.stop();
			self.props.setMarkerOpen(self.props.markerOpen === self.state.markerId
				? -1
				: self.state.markerId);
		});

		this.setState({
			marker,
		});
	}

	componentWillReceiveProps(nextProps) {
		const { marker } = this.state;
		const { map, position } = this.props;

		if (marker && nextProps.position !== position) {
			marker.setPosition(nextProps.position);
		}
		if (marker && nextProps.map !== map) {
			marker.setMap(nextProps.map);
		}
	}

	componentWillUnmount() {
		const { marker } = this.state;
		if (marker) {
			marker.setMap(null);
		}
	}

	closeInfoWindow() {
		const { setMarkerOpen } = this.props;
		setMarkerOpen(-1);
	}

	render() {
		const { children } = this.props;

		return (
			<MarkerContext.Provider value={this.state}>
				{ children }
			</MarkerContext.Provider>
		);
	}
}

Marker.propTypes = {
	children: PropTypes.node,
	position: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	setMarkerOpen: PropTypes.func.isRequired,
};

Marker.defaultProps = {
	children: null,
	position: undefined,
};

export default props => (
	<MapContext.Consumer>
		{
			({ map, markerOpen, setMarkerOpen }) => (
				<Marker
					map={map}
					markerOpen={markerOpen}
					setMarkerOpen={setMarkerOpen}
					{...props}
				/>
			)
		}
	</MapContext.Consumer>
);
