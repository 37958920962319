import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import React from 'react';

import Page from '../components/page/Page';

import './404.scss';

const PageNotFound = () => (
	<Page className="PageNotFound">
		<Helmet>
			<title>cherchebienneuf.fr – 404</title>
		</Helmet>
		<section className="PageContact-container page-center">
			<section>
				<p>
					La page que vous avez demandée n&apos;est pas disponible ou n&apos;existe plus.
				</p>
				<p>
					Veuillez nous excuser pour cet incident.
				</p>
				<Link className="btn btn-error" to="/">Retour sur la page d&apos;accueil</Link>
			</section>
		</section>
	</Page>
);

export default PageNotFound;
