import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

//import logoCecaz from '../../images/logo-ce.png';
import logoCecaz from '../../images/logo-cecaz-RGB.png';
import logoCecazW from '../../images/logo-cecaz-white.png';

import './header.scss';

const HEADER_TYPE_NORMAL = 'HEADER_TYPE_NORMAL';
const HEADER_TYPE_SEARCH = 'HEADER_TYPE_SEARCH';
const HEADER_TYPE_PROGRAMME = 'HEADER_TYPE_PROGRAMME';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = { top: false };
		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll() {
		this.setState({
			top: window.pageYOffset <= 10,
		});
	}

	render() {
		const { children, fixed, location } = this.props;
		const { top } = this.state;

		let headerType = HEADER_TYPE_NORMAL;
		if (/^\/search/.test(location.pathname)) headerType = HEADER_TYPE_SEARCH;
		if (/^\/programme/.test(location.pathname)) headerType = HEADER_TYPE_PROGRAMME;

		const redClass = headerType === HEADER_TYPE_NORMAL ? '' : 'Header-red';
		return (
			<header className={`Header ${fixed ? 'fixed' : ''} ${!top ? 'scrolled' : ''} ${redClass}`}>
				<div className="Header-container page-center">
					<Link to="/">
						{
							headerType === HEADER_TYPE_NORMAL
								? <img alt="Logo Caisse d'Epargne Côte d'Azur" src={logoCecaz} className="Header-logo" title="Accueil" />
								: <img alt="Logo Caisse d'Epargne Côte d'Azur" src={logoCecazW} className="Header-logo " title="Accueil" />
						}
					</Link>
					{ children }
				</div>
			</header>
		);
	}
}


Header.propTypes = {
	children: PropTypes.node,
	fixed: PropTypes.bool,
	location: ReactRouterPropTypes.location.isRequired,
};

Header.defaultProps = {
	children: null,
	fixed: false,
};

export default Header;
