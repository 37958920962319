import ReactRouterPropTypes from 'react-router-prop-types';
import Helmet from 'react-helmet';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Page from '../../components/page/Page';
import ProgrammeDescription from '../../components/programme/programme-description';
import ProgrammeList from '../../components/programme/programme-list';
import SocialsShare from '../../components/socials/share';

// Actions
import { getCurrentProgramme, getOtherProgrammes } from '../../store/actions/programme';

// style
import './programme.scss';

class ProgrammePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pending: true,
		};
		const { loadProgrammes } = this.props;
		loadProgrammes().finally(() => {
			this.setState({
				pending: false,
			});
		});
	}

/*	componentWillReceiveProps(nextProps) {
		console.log('componentWillReceiveProps', nextProps.match.params.uuid);
		const { match, loadProgrammes } = this.props;
		if (match.params.uuid !== nextProps.match.params.uuid) {
			loadProgrammes(nextProps.match.params.uuid);
		}
	}*/

	componentDidUpdate(prevProps) {
		const { match, loadProgrammes } = this.props;
		if (match.params.uuid !== prevProps.match.params.uuid) {
			loadProgrammes(match.params.uuid);
		}
	}

	render() {
		const { location, programme, otherProgrammes } = this.props;
		const { pending } = this.state;
		if (pending === false && Object.keys(programme).length === 0) {
			return <Redirect to="/404" />;
		}

		return (
			<Page className="ProgrammePage">
				<Helmet>
					{
						programme.name
						&& <title>cherchebienneuf.fr – {`${programme.name}`}</title>
					}
					{
						programme.name && <meta property="og:title" content={programme.name} />
					}
					{
						programme.description && <meta property="og:description" content={`${programme.description.substring(0, 150)}...`} />
					}
					{
						programme.files && programme.files[0] && <meta property="og:image" content={`${process.env.REACT_APP_AWS_S3_URL}${programme.files[0].name}`} />
					}
					<meta property="og:url" content={`${process.env.REACT_APP_WWW}${location.pathname}`} />

				</Helmet>
				<ProgrammeDescription programme={programme} />
				<section className="share-programmes page-center">
					<h2 className="section-title">Partager ce programme</h2>
					{
						programme.uuid
							&& (<SocialsShare url={window.location.href} programmeUuid={programme.uuid} />)
					}
				</section>
				{
					(otherProgrammes && otherProgrammes.length)
						? (
							<>
								<section className="other-programmes last-section">
									<h2 className="section-title">Programmes du même promoteur</h2>
									<ProgrammeList
										className="page-center"
										programmes={otherProgrammes}
									/>
								</section>
							</>
						)
						: null
				}
			</Page>
		);
	}
}

ProgrammePage.propTypes = {
	location: ReactRouterPropTypes.location.isRequired,
	loadProgrammes: PropTypes.func.isRequired,
	match: PropTypes.shape({ params: PropTypes.shape({ uuid: PropTypes.string }) }).isRequired,
	programme: PropTypes.shape({
		commentary: PropTypes.string,
		description: PropTypes.string,
		files: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string,
		})),
		name: PropTypes.string,
		title: PropTypes.string,
		uuid: PropTypes.string,
	}).isRequired,
	otherProgrammes: PropTypes.arrayOf(PropTypes.object),
};

ProgrammePage.defaultProps = {
	otherProgrammes: [],
};

const mapStateToProps = state => ({
	programme: state.programme.currentProgramme,
	otherProgrammes: state.programme.otherProgrammes,
});

const mapDispatchToProps = (dispatch, ownProps) => {
	let { uuid } = ownProps.match.params;
	return {
		loadProgrammes: (newUuid) => {
			// cannot use the uuid on upper scope when loadProgrammes is called
			// by the componentWillReceiveProps
			uuid = newUuid || uuid;
			if (!uuid) {
				throw new Error('Invalid uuid.');
			}
			return Promise.all([
				dispatch(getCurrentProgramme(uuid)),
				dispatch(getOtherProgrammes(uuid)),
			]);
		},
	};
};

const ProgrammePageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProgrammePage);

export default ProgrammePageContainer;
