import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import './footer.scss';

const Footer = ({ lp }) => (
	<footer className="Footer">
		<div className="Footer-links section">
			<div className="page-center">
				<div className="Footer-links-cols">
					<ul className="Footer-links-list list-undecorated">
						<li><a href="https://www.caisse-epargne.fr/cote-d-azur">Caisse d&apos;Epargne Côte d&apos;Azur</a></li>
						<li><a href="https://luxuryproperties-caisse-epargne.fr">Luxury Properties</a></li>
					</ul>
					<ul className="Footer-links-list list-undecorated">
						<li>
							<Link to="/conditions-generales-utilisation">
								Conditions générales d&apos;utilisation
							</Link>
						</li>
						<li><Link to="/mentions-legales">Mentions légales</Link></li>
						<li><Link to="/politique-cookies">Politiques des cookies</Link></li>
					</ul>
					<ul className="Footer-links-list list-undecorated">
						<li>
							<a href="https://www.caisse-epargne.fr/cote-d-azur/particuliers/emprunter/dossier-immobilier">
								Nos conseils pour acheter
							</a>
						</li>
						{
							!lp
							&& (
								<>
									<li><Link to="/pret-taux-zero">Le Prêt à Taux Zéro</Link></li>
									<li><Link to="/loi-pinel">La Loi Pinel</Link></li>
								</>
							)
						}
					</ul>
					<div className="Footer-social-networks">
						<p>Retrouvez-nous et suivez-nous</p>
						<ul className="Footer-social-networks-list list-undecorated">
							<li>
								<a href="https://www.facebook.com/caissedepargnecotedazur">
									<i className="ic ic-facebook" aria-hidden="true" />
								</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/company/3107203/">
									<i className="ic ic-linkedin" aria-hidden="true" />
								</a>
							</li>
							<li>
								<a href="https://twitter.com/CE_CotedAzur">
									<i className="ic ic-twitter" aria-hidden="true" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div className="Footer-legal section">
			<p className="page-center">
				© Caisse d&apos;Epargne Côte d&apos;Azur. Tous droits réservés.
			</p>
		</div>
	</footer>
);

Footer.propTypes = {
	lp: PropTypes.bool,
};

Footer.defaultProps = {
	lp: false,
};

export default React.memo(Footer);
