import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

// Components
import LegalArticle from '@cecaz-immo/ui/dist/components/legal/article';
import LegalSection from '@cecaz-immo/ui/dist/components/legal/section';
import Page from '../components/page/Page';
import ProgrammeList from '../components/programme/programme-list';

// Actions
import { getLastProgrammes } from '../store/actions/programme';

import logoPinel from '../images/logo-pinel.png';

/* eslint-disable max-len */
class PageLoiPinel extends React.Component {
	constructor(props) {
		super(props);
		//Search programmes
		const { getLastProgrammes: actionGetLastProgrammes } = this.props;
		actionGetLastProgrammes();
	}

	render() {
		const { lastProgrammes } = this.props;
		return (
			<Page className="PageLoiPinel">
				<Helmet>
					<title>cherchebienneuf.fr – La loi Pinel</title>
				</Helmet>
				<section className="page-center section">
					<LegalSection>
						<h1 className="section-title title-img">
							La loi Pinel
							<img src={logoPinel} alt="logo loi Pinel" width="410" />
						</h1>
						<p><strong>La loi Pinel est reconduite jusqu&apos;à fin 2017 !</strong></p>
						<p>
						Vous souhaitez
							<a
								title="investir pour louer"
								href="https://www.iselection.com/b2c/votre-projet/L-immobilier-locatif-qui-vous-correspond.html"
							> investir pour louer
							</a>.
						C&apos;est le moment ! La loi Pinel vous
						permet d&apos;acheter sans apport et de réaliser
						jusqu&apos;à 63.000 €
							<a
								title="reduire vos impôts"
								href="https://www.iselection.com/b2c/votre-projet/reduire-vos-impots.html"
							> d&apos;économie d&apos;impôts
							</a> à condition de louer votre bien pendant une durée minimale de 6 ans.
						Vous pouvez ensuite prolonger deux fois pour une durée de 3 ans. Plus vous
						louez longtemps, plus <a href="https://www.iselection.com/b2c/fiscalites/la-loi-pinel#Avantages%20fiscaux%20Pinel">l&apos;avantage fiscal</a> de la loi Pinel est important :
						jusqu&apos;à 21 % du prix de votre achat immobilier.
						</p>
						<LegalArticle>
							<h2>Les biens éligibles par la loi Pinel</h2>
							<ul>
								<li>Les biens neufs ou VEFA</li>
								<li>Bénéficiant du label BBC 2005 ou RT 2012 (Réglementation Thermique)</li>
								<li>Acquis entre le 01/09/2014 et le 31/12/2016</li>
								<li>Situés dans des <a href="https://www.iselection.com/b2c/fiscalites/la-loi-pinel#zones-pinel">zones géographiques</a> de la loi Pinel (A et Abis, B1, B2)</li>
							</ul>
						</LegalArticle>
						<LegalArticle>
							<h2>Quel cadre fiscal pour la loi Pinel ?</h2>
							<ul>
								<li>Le taux de réduction d’impôts s’applique sur le prix de revient du bien immobilier <br />
							(respectant une <a href="https://www.iselection.com/b2c/fiscalites/la-loi-pinel#obligation-pinel-a-respecter">limite</a> fixée par les services fiscaux)
								</li>
								<li>La réduction d’impôts commence l&apos;année de l&apos;achèvement de l&apos;immeuble ou de son acquisition
								si elle est postérieure à l’achèvement.
								</li>
								<li>Vous pouvez réaliser 2 achats par an</li>
								<li>Un déficit foncier imputable limité à 10.700 € par an</li>
							</ul>
						</LegalArticle>
						<LegalArticle>
							<h2>Quelles sont les conditions requises pour bénéficier de la loi Pinel ?</h2>
							<ul>
								<li>Vous devez louer votre bien pendant au moins 6 ans (prolongeable sur 9 ou 12 ans) dans les 12 mois qui suivent sa livraison</li>
								<li>Votre bien doit être l’habitation principale de votre locataire</li>
								<li>Vous pouvez louez à vos ascendants ou descendants (s’ils vivent hors du foyer fiscal et si vous respectez les plafonds de loyers)</li>
								<li>Votre locataire doit remplir des critères d’éligibilité. Son revenu fiscal doit respecter <a href="https://www.iselection.com/b2c/fiscalites/la-loi-pinel#Plafonds-Ressources-Pinel">les plafonds de ressources</a> de la loi Pinel</li>
								<li>Votre loyer mensuel par m2 ne doit pas être supérieur à un <a href="https://www.iselection.com/b2c/fiscalites/la-loi-pinel#plafonds-loyers">plafond fixé par décret</a>.</li>
							</ul>
						</LegalArticle>
						<LegalArticle>
							<h2>Obligations à respecter</h2>
							<p>Afin de bénéficier des avantages du Pinel, un certain nombre de conditions doivent être respectées :</p>
							<ul>
								<li><strong>Point de départ de votre réduction d’impôt</strong> : la première réduction d&apos;impôt est accordée au titre de l&apos;année d&apos;achèvement du logement acheté sur plan, ou de l&apos;année de son acquisition si le bien est déjà achevé.</li>
								<li>Les services fiscaux fixent une <strong>limite de 5.500 € par mètre carré</strong> de surface habitable pour le calcul de la réduction d’impôt. Rien ne vous interdit d&apos;acheter un bien immobilier dépassant les 5.500€ au mètre carré, mais dans ce cas-là, seule la partie égale à 5 500€/m² bénéficiera de la réduction d&apos;impôt.</li>
								<li><strong>Le montant total</strong> de l&apos;acquisition pris en compte par l&apos;administration fiscale est de : 300.000 €. En cas d’acquisition d’un montant supérieur, seul le montant de 300.000 € sera pris en compte dans le calcul de la réduction d’impôt.</li>
								<li><strong>Nombre de biens</strong> : il est possible d&apos;acheter 2 biens par année civile.</li>
								<li><strong>Déficits fonciers</strong> éventuels permettant d’éteindre d&apos;autres revenus fonciers et/ou s&apos;imputant sur le revenu global dans la limite de 10.700 € par an.</li>
							</ul>
						</LegalArticle>
					</LegalSection>
				</section>
				<section className="last-offers section last-section">
					<h1 className="section-title">Nos dernières offres</h1>
					<ProgrammeList
						className="page-center"
						programmes={lastProgrammes}
					/>
				</section>
			</Page>
		);
	}
}

PageLoiPinel.propTypes = {
	getLastProgrammes: PropTypes.func.isRequired,
	lastProgrammes: PropTypes.arrayOf(PropTypes.shape({})),
};

PageLoiPinel.defaultProps = {
	lastProgrammes: [],
};

const mapStateToProps = state => ({
	lastProgrammes: state.programme.lastProgrammes,
});

const mapDispatchToProps = dispatch => ({
	getLastProgrammes: () => dispatch(getLastProgrammes()),
});

export const PageLoiPinelContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PageLoiPinel);

export default PageLoiPinelContainer;
