import PropTypes from 'prop-types';
import React from 'react';

import { getFileUrl } from '../../lib/file';

import './media.scss';

class Media extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			paused: true,
		};

		this.onClickVideo = this.onClickVideo.bind(this);
		this.onVideoStatusChange = this.onVideoStatusChange.bind(this);
	}

	onClickVideo() {
		if (this.videoElement.paused) {
			this.videoElement.play();
		} else {
			this.videoElement.pause();
		}
	}

	onVideoStatusChange() {
		this.setState({ paused: this.videoElement.paused });
	}

	render() {
		const { alt, file } = this.props;
		const { type } = file;
		const url = getFileUrl(file);
		const { paused } = this.state;

		if (type === 'video/mp4') {
			return (
				<div
					className="Media Media-video"
					onClick={this.onClickVideo}
				>
					<video
						src={url}
						ref={(videoElement) => { this.videoElement = videoElement; }}
						onPause={this.onVideoStatusChange}
						onPlay={this.onVideoStatusChange}
					/>
					<div className={`video-overlay ${(!paused ? 'hidden' : '')}`}>
						<span className="fa fa-play-circle play-button" />
					</div>
				</div>
			);
		}

		return <img className="Media" src={url} alt={alt} />;
	}
}

Media.propTypes = {
	alt: PropTypes.string.isRequired,
	file: PropTypes.shape({}),
	type: PropTypes.string,
};

Media.defaultProps = {
	file: {},
	type: '',
};

export default Media;
