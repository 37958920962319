import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import Select from './Select';

class FormSelectAsync extends PureComponent {
	render() {
		const {
			...props
		} = this.props;

		return (
			<Select
				component={AsyncSelect}
				{...props}
			/>
		);
	}
}

FormSelectAsync.propTypes = {

};

export default FormSelectAsync;
